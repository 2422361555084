import { Space } from 'antd';

import { Typography } from '@Shared/ui';

const { Text } = Typography;
type RowError = {
    message: string;
    row: number;
};

export const ImportErrorMessage = ({ errors }: { errors: RowError[] }) => {
    const postfixErrorMessage = errors?.length > 3 ? '...' : '';
    if (!errors?.length) {
        return null;
    }
    return (
        <Space
            direction="vertical"
            style={{
                gap: 0,
                maxWidth: 450,
            }}
        >
            <Text
                style={{
                    display: 'block',
                    textAlign: 'start',
                }}
            >
                Не удалось обновить настройки {errors?.length} товаров:
            </Text>
            <Space
                direction="vertical"
                style={{
                    paddingLeft: 16,
                    gap: 0,
                }}
            >
                {errors.slice(0, 3).map((currentError, index) => {
                    const isLast = index === 2;
                    const postfixErrorString = isLast ? '.' : ';';
                    return (
                        <Text
                            style={{
                                display: 'list-item',
                                listStyle: 'disc',
                                textAlign: 'start',
                            }}
                            key={currentError?.row}
                        >
                            {`Строка ${currentError?.row}: ${currentError?.message}${postfixErrorString}`}
                            {isLast && postfixErrorMessage}
                        </Text>
                    );
                })}
            </Space>
        </Space>
    );
};
