import ym, { withId } from 'react-yandex-metrika';

import { hasYmAccounts, ymBusinessId, ymAccountTypes } from '@Shared/constants/analytics';

import { generateDatetimeGreenwich, generateHitId, getUrlTags, uuidv4 } from './metrikaUtils';

const locale = 'ru';

interface EventInfo {
    [key: string]: string;
}

const getValue = (value: string | null | undefined) => {
    return value || '-';
};

export const sendYmEvent = ({
    name,
    o1,
    o2,
    o4,
    keycloakId,
    advertiserId,
    publisherId,
    advcampaignId,
    programId,
    websiteId,
    sendboxId,
    accountType = 'all',
}: {
    name: string;
    o1: string;
    o2?: string;
    o4?: string;
    keycloakId?: string | null;
    advertiserId?: string | null;
    publisherId?: string | null;
    advcampaignId?: string | null;
    programId?: string | null;
    websiteId?: string | null;
    sendboxId?: string | null;
    accountType?: ymAccountTypes;
}) => {
    if (!keycloakId || !hasYmAccounts) {
        return;
    }

    const uu_id = uuidv4();
    const datetimeGreenwich = generateDatetimeGreenwich();
    const hitIdValue = generateHitId();
    const urlTags = getUrlTags();

    const event_info: EventInfo = {};
    event_info[uu_id] = [
        `en:${name}`,
        `o1:${getValue(o1)}`,
        `o2:${getValue(o2)}`,
        'o3:takprodam',
        `o4:${getValue(o4)}`,
        `dg:${datetimeGreenwich}`,
        `hi:${hitIdValue}`,
        `lo:${locale}`,
        `ut:${urlTags}`,
        `pi:${getValue(programId)}`,
        `ai:${getValue(advertiserId)}`,
        `of:${getValue(advcampaignId)}`,
        `ui:${getValue(publisherId)}`,
        `ki:${keycloakId}`,
        `wi:${getValue(websiteId)}`,
        'pp:-',
        're:-',
        'ab:-',
        `si:${getValue(sendboxId)}`,
    ].join('|');

    if (accountType === 'all') {
        ym('params', event_info);
    } else if (accountType === 'business') {
        if (!ymBusinessId) {
            return;
        }

        withId(ymBusinessId)('params', event_info);
    }
};

export const sendBusinessYmReachGoal = (target: string) => {
    if (!ymBusinessId) {
        return;
    }

    withId(ymBusinessId)('reachGoal', target);
};
