import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Space, Button } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { CopyCardValue } from '@Features/shared';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { Title, Text, Link } = Typography;

const RefLinkModal = () => {
    const dispatch = useAppDispatch();
    const modalState = useAppSelector(selectModals);
    const isOpen = modalState.modalKey === ModalKeysType.refLink && modalState.open;
    const { data: user } = useGetTheUserInformationQuery();
    const refCode = user?.referral_code || '';

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const YmCopyLinkEvent = () => {
        sendYmEvent({
            name: 'click_copy_referral_link',
            o1: 'account;advertiser;referral link',
            o2: 'referral link',
            o4: refCode || '',
            advertiserId: user?.seller?.id,
            keycloakId: user?.mitgo,
            accountType: 'business',
        });
    };

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{ marginTop: 0 }}
                >
                    Ваша реферальная ссылка-приглашение
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="cancel"
                    onClick={handleCancel}
                >
                    Готово
                </Button>,
            ]}
        >
            <Space
                direction="vertical"
                size={20}
                style={{ width: '100%' }}
            >
                <Row>
                    <Text>
                        Вам доступна уникальная ссылка, по которой новый продавец или паблишер может зарегистрироваться
                        на ТакПродам. Поделитесь ей и зарабатывайте комиссию за приглашенных активных пользователей.
                    </Text>
                </Row>
                <CopyCardValue
                    cardValue={refCode ? `${process.env.APP_URL}/?ref=${refCode}` : ''}
                    copyMessageText="Реферальная ссылка-приглашение успешно скопирована"
                    buttonProps={{ type: 'primary' }}
                    ymEvent={YmCopyLinkEvent}
                />
                <Row>
                    <Text>
                        Подробнее об{' '}
                        <Link
                            href="https://storage.yandexcloud.net/uploads-prod/public/refprogram.pdf"
                            target="_blank"
                        >
                            Условиях реферальной программы
                        </Link>
                    </Text>
                </Row>
            </Space>
        </Modal>
    );
};
export default RefLinkModal;
