import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSellerTransactionsList: build.query<GetSellerTransactionsListApiResponse, GetSellerTransactionsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/transaction/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    createdAtFrom: queryArg.createdAtFrom,
                    createdAtTo: queryArg.createdAtTo,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerTransactionsApi };
export type GetSellerTransactionsListApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: TransactionDto[];
};
export type GetSellerTransactionsListApiArg = {
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum2;
    createdAtFrom?: string | null;
    createdAtTo?: string | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type SourceTypeDto = {
    title?: string | null;
    slug?: string | null;
};
export type SourceDto4 = {
    source_url?: string | null;
    source_type?: SourceTypeDto | null;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace2 | null;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type Product = {
    id: number;
    marketplace_store?: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory | null;
    image_url?: string | null;
};
export type MpTrafficReportDto = {
    date: string;
};
export type CpcCommissionDetailDto = {
    clickCount?: number | null;
    ecpc?: number | null;
    date?: string | null;
};
export type CommissionDto = {
    id: string;
    status: CommissionStatusEnum;
    source?: SourceDto4 | null;
    decline_reason?: CommissionDeclineReasonEnum2 | null;
    change_total_cart_reason?: CommissionChangeTotalCartReasonEnum2 | null;
    marketplace_store?: MarketplaceStore3 | null;
    order_product?: Product | null;
    click_product?: Product | null;
    total_cart?: number | null;
    total_fee?: number | null;
    fee_value?: number | null;
    mp_traffic_report?: MpTrafficReportDto | null;
    cpc_commission_detail?: CpcCommissionDetailDto | null;
};
export type TransactionDto = {
    id: string;
    transaction_type: TransactionTypeEnum;
    transaction_amount: number;
    currency: Currency;
    commission?: CommissionDto | null;
    created_at: string;
};
export enum TransactionTypeEnum {
    Commission = 'commission',
    Replenishment = 'replenishment',
    Payout = 'payout',
    Manual = 'manual',
}
export enum CommissionStatusEnum {
    Pending = 'pending',
    Approved = 'approved',
    Declined = 'declined',
}
export enum CommissionDeclineReasonEnum2 {
    Refusal = 'refusal',
    NotRedeemed = 'not_redeemed',
    Refund = 'refund',
}
export enum CommissionChangeTotalCartReasonEnum2 {
    PartiallyPaid = 'partially_paid',
}
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum SortOrderEnum2 {
    Asc = 'asc',
    Desc = 'desc',
}
export const { useGetSellerTransactionsListQuery } = injectedRtkApi;
