import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Space, Button, Row, Grid } from '@Shared/ui';
import { useGetSellerPromotionsListQuery, PromotionTypeEnum2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/promotions/personalPromotionsTab/model/types';
// eslint-disable-next-line max-len
import PersonalPromotionDetailsDrawer from '@Features/seller/promotions/personalPromotionsTab/personalPromotionsDrawer/PersonalPromotionDetailsDrawer';
import { useUpdateSearchParams } from '@Shared/hooks';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

import { DeclinePromotionParticipationModal, DeletePromotionModal } from './modals';
import PersonalPromotionsTable from './personalPromotionsTable/PersonalPromotionsTable';
import PromotionsFilters from './personalPromotionsFilters/PersonalPromotionsFilters';

const { useBreakpoint } = Grid;

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    storeId: searchParams.get(FilterEnum.STORE_ID) || '',
    promotionTypes: searchParams.getAll(FilterEnum.PROMOTION_TYPES),
});

const PersonalPromotionsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const updateSearchParams = useUpdateSearchParams();
    const [currentPromotionId, setPromotionId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const { data: user } = useGetTheUserInformationQuery();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetSellerPromotionsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        storeId: filterObject.storeId || '',
        active: filterObject?.active || undefined,
        types: filterObject?.promotionTypes?.length
            ? filterObject.promotionTypes
            : [PromotionTypeEnum2.Coupon, PromotionTypeEnum2.Sale],
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    const handleClickAddPromotion = () => {
        navigate('/promotions/personal-promotions/add-personal-promotion');
        sendYmEvent({
            name: 'click_promotion_add',
            o1: 'account;advertiser;promotions and promocodes',
            o2: 'add promo',
            advertiserId: user?.seller?.id,
            keycloakId: user?.mitgo,
        });
    };

    return (
        <>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Row
                    justify="space-between"
                    style={{ gap: 16, flexDirection: breakpoints.lg ? 'row' : 'column-reverse' }}
                >
                    <PromotionsFilters
                        filterObject={filterObject}
                        setFilterObject={setFilterObject}
                    />
                    <Button
                        type="primary"
                        style={{ width: breakpoints.md ? 120 : '100%', transition: 'unset' }}
                        onClick={handleClickAddPromotion}
                    >
                        Добавить
                    </Button>
                </Row>
                <PersonalPromotionsTable
                    data={data}
                    isLoading={isFetching}
                    setFilterObject={setFilterObject}
                    setPromotionId={setPromotionId}
                    setDrawerOpen={setDrawerOpen}
                />
            </Space>
            <PersonalPromotionDetailsDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                promotionId={currentPromotionId}
            />
            <DeclinePromotionParticipationModal />
            <DeletePromotionModal />
        </>
    );
};
export default PersonalPromotionsTableBlock;
