import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Alert, Space } from '@Shared/ui';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection/types';
import { useUpdateSearchParams } from '@Shared/hooks';
import { useGetProductsListForCampaignQuery } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { CheckMark } from '@Shared/assets/images/icons';
import { FullVisibilityAlert, FullVisibilityAlertEntityType } from '@Features/shared';

import CampaignProductsTable from './CampaignProductsTable';
import CampaignProductsFilters from './campaignProductsFilters/CampaignProductsFilters';
import { ImportCampaignProductsModal } from './modals';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    search_string: searchParams.get(FilterEnum.SEARCH_STRING) || '',
    visible: Boolean(searchParams.get(FilterEnum.VISIBLE)),
});

const CampaignProductsTableBlock = ({ campaignSlug }: { campaignSlug: string }) => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const [successImportMessage, setSuccessImportMessage] = useState('');
    const {
        data: campaignProducts,
        isFetching: isPromotionsLoading,
        refetch,
    } = useGetProductsListForCampaignQuery(
        {
            slug: campaignSlug,
            page: filterObject.page,
            limit: filterObject.limit,
            searchString: filterObject?.search_string || '',
            visible: filterObject?.visible || undefined,
        },
        { skip: !campaignSlug },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    const handleImportSuccess = (successMessage: string) => {
        setSuccessImportMessage(successMessage);
        refetch();
    };

    const handleCloseSuccessImportAlert = () => {
        setSuccessImportMessage('');
    };

    return (
        <Space
            direction="vertical"
            size={24}
            style={{ width: '100%' }}
        >
            <FullVisibilityAlert
                entityType={FullVisibilityAlertEntityType.CAMPAIGN}
                entityIdentificator={campaignSlug}
                isButtonDisabled={isPromotionsLoading || !campaignProducts?.items?.length}
            />
            <CampaignProductsFilters
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                campaignSlug={campaignSlug}
                onImportClick={handleCloseSuccessImportAlert}
            />

            {Boolean(successImportMessage) && (
                <Alert
                    closable
                    message={successImportMessage}
                    type="success"
                    afterClose={handleCloseSuccessImportAlert}
                    showIcon
                    banner
                    icon={<CheckMark />}
                />
            )}
            <CampaignProductsTable
                data={campaignProducts}
                isLoading={isPromotionsLoading}
                setFilterObject={setFilterObject}
            />
            <ImportCampaignProductsModal onSuccess={handleImportSuccess} />
        </Space>
    );
};
export default CampaignProductsTableBlock;
