import { useState, useEffect } from 'react';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Button, Typography, Row, Col, Card, Grid } from '@Shared/ui';
import { useAddOnboardingStatusMutation, useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { OnboardingCallBg } from '@Shared/assets/images/icons';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const OnboardingCallBlock = ({ onboardingStatus }: { onboardingStatus: string }) => {
    const breakpoints = useBreakpoint();
    const { data: user, isSuccess: isUserFulfilled } = useGetTheUserInformationQuery();
    const [addOnboardingStatus] = useAddOnboardingStatusMutation();
    const {
        token: { colorWhite, colorPrimary },
    } = theme.useToken();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (isUserFulfilled && !user?.onboarding_statuses?.includes(onboardingStatus)) {
            setVisible(true);
        }
    }, [user, onboardingStatus, isUserFulfilled]);

    const handleClose = () => {
        setVisible(false);
        addOnboardingStatus({ status: onboardingStatus });
    };

    if (!visible) return null;

    return (
        <Card
            style={{
                borderRadius: '12px',
                color: colorWhite,
                background: colorPrimary,
                marginBottom: 24,
            }}
            styles={{
                body: {
                    padding: '20px 24px',
                },
            }}
        >
            <Icon
                style={{ position: 'absolute', right: 0, top: 0, height: '100%' }}
                component={() => <OnboardingCallBg style={{ height: '100%' }} />}
            />
            <Row
                style={{
                    gap: 8,
                }}
                justify="space-between"
                align="middle"
                wrap={!breakpoints.md}
            >
                <Col>
                    <Title
                        level={5}
                        style={{ color: colorWhite, marginTop: 0 }}
                    >
                        Вы можете забронировать персональный онбординг
                    </Title>
                    <Text style={{ color: colorWhite, fontWeight: 'normal' }}>
                        Для вас доступен персональный онбординг с менеджером для быстрого запуска и заработка.
                        Забронируйте удобное для вас время, оставив данные
                    </Text>
                </Col>
                <Button
                    href="https://forms.yandex.ru/u/679757bceb614637d1231368/"
                    target="_blank"
                    style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                >
                    Забронировать
                </Button>
            </Row>
            <CloseOutlined
                style={{
                    color: '#323232',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 10,
                    right: 10,
                }}
                onClick={handleClose}
            />
        </Card>
    );
};

export default OnboardingCallBlock;
