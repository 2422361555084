import { CommissionStatusEnum } from '@Shared/api/rtkQueryApis/sellerCommissions';

export enum FilterEnum {
    CREATED_AT_FROM = 'createdAtFrom',
    CREATED_AT_TO = 'createdAtTo',
    LIMIT = 'limit',
    PAGE = 'page',
    SKU = 'sku',
    MARKETPLACE_STORE_ID = 'marketplaceStoreId',
}

export type FilterObjectType = {
    createdAtFrom: string;
    createdAtTo: string;
    page: number;
    limit: number;
    sku: string;
    marketplaceStoreId: string;
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const CpcCommissionStatusesMap = {
    [CommissionStatusEnum.Approved]: 'Подтверждена',
    [CommissionStatusEnum.Declined]: 'Отклонена',
    [CommissionStatusEnum.Pending]: 'В ожидании',
};
