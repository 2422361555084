import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSellerClicksStatistics: build.query<GetSellerClicksStatisticsApiResponse, GetSellerClicksStatisticsApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/statistics/clicks/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    search_string: queryArg.searchString,
                    payment_type: queryArg.paymentType,
                },
            }),
        }),
        getSellerCommissionsStatistics: build.query<
            GetSellerCommissionsStatisticsApiResponse,
            GetSellerCommissionsStatisticsApiArg
        >({
            query: (queryArg) => ({
                url: '/api/seller/v1/statistics/commissions/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    search_string: queryArg.searchString,
                    payment_type: queryArg.paymentType,
                    source_types: queryArg.sourceTypes,
                    has_commissions: queryArg.hasCommissions,
                },
            }),
        }),
        exportSellerStatistics: build.query<ExportSellerStatisticsApiResponse, ExportSellerStatisticsApiArg>({
            query: () => ({ url: '/api/seller/v1/statistics/export/' }),
        }),
        sellerStatisticsByProducts: build.query<
            SellerStatisticsByProductsApiResponse,
            SellerStatisticsByProductsApiArg
        >({
            query: () => ({ url: '/api/seller/v1/statistics/product/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerStatisticsApi };
export type GetSellerClicksStatisticsApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: StatisticsClickDto2[];
};
export type GetSellerClicksStatisticsApiArg = {
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum;
    dateFrom?: string | null;
    dateTo?: string | null;
    searchString?: string | null;
    paymentType?: PaymentTypeEnum;
};
export type GetSellerCommissionsStatisticsApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: StatisticsCommissionDto2[];
};
export type GetSellerCommissionsStatisticsApiArg = {
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum;
    dateFrom?: string | null;
    dateTo?: string | null;
    searchString?: string | null;
    paymentType?: PaymentTypeEnum;
    sourceTypes?: {
        ''?: number[];
    };
    hasCommissions?: boolean;
};
export type ExportSellerStatisticsApiResponse = unknown;
export type ExportSellerStatisticsApiArg = void;
export type SellerStatisticsByProductsApiResponse = /** status 200 Resource response */ ProductStatisticListDto2;
export type SellerStatisticsByProductsApiArg = void;
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type SourceTypeDto = {
    title?: string | null;
    slug?: string | null;
};
export type SourceDto4 = {
    source_url?: string | null;
    source_type?: SourceTypeDto | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum5;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type BarterSettingDto3 = {
    id?: string | null;
    advertising_format?: AdvertisingFormatEnum3[];
    integration_number: IntegrationNumberEnum3 | null;
    barter_fee?: number | null;
    payment_type: PaymentTypeEnum6 | null;
    deadline: DeadlineEnum3 | null;
    publisher_requirements: string | null;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto3 = {
    id?: number | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
    publisher_fee?: number | null;
};
export type ProductCampaignDto = {
    id: string;
    campaign?: CampaignDto3 | null;
    product?: ProductDto | null;
    fee_setting?: FeeSettingDto | null;
    visible?: boolean;
    favorite?: boolean;
    tracking_link?: string | null;
    legal_text?: string | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
    tracking_short_link?: string | null;
};
export type CampaignDto2 = {
    slug?: string | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    daily_budget?: number | null;
    fee_setting: FeeSetting2 | null;
    barter_setting?: BarterSettingDto3 | null;
    product_campaigns?: ProductCampaignDto[];
    source_campaign_fees?: SourceCampaignFee[];
};
export type StatisticsClickDto2 = {
    date: string;
    product?: ProductDto | null;
    source?: SourceDto4 | null;
    campaign?: CampaignDto2 | null;
    clicks?: number;
    clicks_approved?: number;
};
export type StatisticsCommissionDto2 = {
    product?: ProductDto | null;
    campaign?: CampaignDto2 | null;
    source?: SourceDto4 | null;
    clicks?: number;
    commissions?: number;
    commission_sum?: number;
    cr?: number;
    ecpc?: number;
};
export type ProductStatisticDto2 = {
    store_title: string;
    marketplace_title: string;
    product_sku: string;
    product_title: string;
    clicks: number;
    commissions: number;
    amount: number;
};
export type ProductStatisticListDto2 = {
    items?: ProductStatisticDto2[];
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum PaymentTypeEnum5 {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
    Cpmc = 'cpmc',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
    Disconnected = 'disconnected',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum AdvertisingFormatEnum3 {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum3 {
    $30 = '30',
    $60 = '60',
    $90 = '90',
}
export enum PaymentTypeEnum6 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum3 {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum SortOrderEnum {
    Asc = 'asc',
    Desc = 'desc',
}
export enum PaymentTypeEnum {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
    Cpmc = 'cpmc',
}
export const {
    useGetSellerClicksStatisticsQuery,
    useGetSellerCommissionsStatisticsQuery,
    useExportSellerStatisticsQuery,
    useSellerStatisticsByProductsQuery,
} = injectedRtkApi;
