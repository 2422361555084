import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import { useAppSelector, useAppDispatch, useUpdateSearchParams } from '@Shared/hooks';
import { setRun, goToStep } from '@Entities/joyride/model/joyrideSlice';
import { PublisherOnboardingStatuses } from '@Shared/model/joyrideTypes';
import {
    useGetProductsListWithLinksQuery,
    PaymentTypeEnum,
    SortOrderEnum,
} from '@Shared/api/rtkQueryApis/publisherSources';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
import { Row, Typography, Space, Button } from '@Shared/ui';
import { SourceSelectBlock } from '@Features/shared';
import { ProductCatalogTable, ProductCatalogDrawer, ProductCatalogFilters } from '@Features/publisher/products/cpa';
import { FilterObjectType, FilterEnum, defaultFilterObject } from '@Features/publisher/products/cpa/model/types';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { Text } = Typography;

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    stores: searchParams.getAll(FilterEnum.STORES) || [],
    marketplaces: searchParams.getAll(FilterEnum.MARKETPLACES),
    categories: searchParams.getAll(FilterEnum.CATEGORIES),
    favorite: Boolean(searchParams.get(FilterEnum.FAVORITE)),
    search_string: searchParams.get(FilterEnum.SEARCH_STRING) || '',
    payment_type: PaymentTypeEnum.Cpa,
    sortField: searchParams.get(FilterEnum.SORT_FIELD) || '',
    sortOrder: (searchParams.get(FilterEnum.SORT_ORDER) as SortOrderEnum) || undefined,
});

const ProductsTab = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const updateSearchParams = useUpdateSearchParams();
    const { status: onboardingStepStatus, stepIndexOrder } = useAppSelector((state) => state.joyride);
    const [selectedSourceId, setSourceId] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState('');
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data: user } = useGetTheUserInformationQuery();
    const { data: productsData, isFetching: isProductsLoading } = useGetProductsListWithLinksQuery({
        source: selectedSourceId,
        page: filterObject.page,
        limit: filterObject.limit,
        favorite: filterObject?.favorite || undefined,
        stores: filterObject?.stores?.length ? filterObject.stores : undefined,
        marketplaces: filterObject?.marketplaces?.length ? filterObject.marketplaces : undefined,
        categories: filterObject?.categories?.length ? filterObject.categories : undefined,
        searchString: filterObject?.search_string || '',
        paymentType: filterObject?.payment_type || PaymentTypeEnum.Cpa,
        sortField: filterObject?.sortField || undefined,
        sortOrder: filterObject?.sortOrder || undefined,
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    // joyride onboarding step
    useEffect(() => {
        if (onboardingStepStatus === PublisherOnboardingStatuses.GETTING_STARTED) {
            if (stepIndexOrder === 1) {
                dispatch(setRun(true));
                dispatch(goToStep({ status: PublisherOnboardingStatuses.GETTING_STARTED, order: 2 }));
            } else if (stepIndexOrder === 4) {
                dispatch(setRun(true));
            }
        }
    }, [dispatch, onboardingStepStatus, stepIndexOrder]);

    const handleSetSourceId = (sourceId: string) => {
        sendYmEvent({
            name: 'click_catalog_filter',
            o1: 'account;publisher;catalog',
            o2: 'filter;change adspace',
            o4: sourceId,
            publisherId: user?.publisher?.id,
            keycloakId: user?.mitgo,
        });
        setSourceId(sourceId);
    };

    return (
        <Space
            direction="vertical"
            size="large"
            style={{ width: '100%' }}
        >
            {!user?.publisher?.legalInfo && (
                <Row style={{ marginBottom: 24 }}>
                    <Alert
                        className="campaigns-tab__legal-info-alert"
                        // eslint-disable-next-line max-len
                        description="Чтобы увидеть партнёрские ссылки, заполните юридические данные. Они необходимы для соблюдения закона о маркировке рекламы."
                        type="info"
                        showIcon
                        icon={<InfoCircleFilled style={{ color: 'black', opacity: '0.45' }} />}
                        banner
                        action={
                            <Button
                                type="primary"
                                onClick={() => navigate('/legal-info')}
                            >
                                Заполнить
                            </Button>
                        }
                    />
                </Row>
            )}
            <Row align="top">
                <Text>
                    В этом подразделе представлен список доступных для продвижения продуктов, работающих по модели
                    оплаты “Оплата за заказ (CPA).
                </Text>
            </Row>
            <SourceSelectBlock
                setSourceId={handleSetSourceId}
                selectedSourceId={selectedSourceId}
            />
            <ProductCatalogFilters
                filterObject={filterObject}
                setFilterObject={setFilterObject}
                sourceId={selectedSourceId}
            />
            <ProductCatalogTable
                data={productsData}
                isProductsLoading={isProductsLoading}
                setFilterObject={setFilterObject}
                setCurrentProductId={setCurrentProductId}
                setDrawerOpen={setDrawerOpen}
                filterObject={filterObject}
            />
            <ProductCatalogDrawer
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
                productId={currentProductId}
                sourceId={selectedSourceId}
            />
        </Space>
    );
};
export default ProductsTab;
