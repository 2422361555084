import { FC } from 'react';
import { theme } from 'antd';

import { Space, Row, Typography, Divider, Descriptions, Flex } from '@Shared/ui';
import { PromotionDto3 } from '@Shared/api/rtkQueryApis/sellerPromotions';

import ActionButtons from './ActionButtons';

import { MPTitleCell, StatusCell, PeriodCell } from '../promotionsTable/cells';

const { Title } = Typography;

type PromotionDetailsProps = {
    promotion: PromotionDto3;
};

export const PromotionDetails: FC<PromotionDetailsProps> = ({ promotion }) => {
    const {
        token: { colorBgLayout, borderRadius, padding, paddingLG },
    } = theme.useToken();

    const detailsKeyValueData = [
        { key: 'Статус', value: <StatusCell isActive={!!promotion.active} /> },
        { key: 'Период', value: <PeriodCell promotion={promotion} /> },
        { key: 'Скидка', value: promotion.discount_value ? `${promotion?.discount_value}%` : null },
    ].filter((keyValue) => keyValue.value);

    return (
        <Flex
            gap="middle"
            vertical
            justify="space-between"
            style={{ height: '100%' }}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={20}
            >
                <Title
                    level={4}
                    style={{ margin: 0 }}
                >
                    {promotion?.title || ''}
                </Title>
                <div dangerouslySetInnerHTML={{ __html: promotion?.description || '' }}></div>
                <Row
                    style={{
                        background: colorBgLayout,
                        padding: `${padding}px ${paddingLG}px`,
                        borderRadius: borderRadius,
                    }}
                >
                    <Descriptions
                        column={1}
                        title={
                            <Title
                                level={5}
                                style={{ marginTop: 0 }}
                            >
                                Детали акции
                            </Title>
                        }
                    >
                        {detailsKeyValueData.map((item, index) => (
                            <Descriptions.Item
                                label={item.key}
                                key={index}
                                contentStyle={{ justifyContent: 'flex-end' }}
                            >
                                {item.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Row>
                <Row style={{ flexDirection: 'column' }}>
                    <Title
                        level={5}
                        style={{ marginTop: 0 }}
                    >
                        Магазин
                    </Title>
                    <Row style={{ gap: 16 }}>
                        <Divider style={{ margin: 0 }} />
                        <MPTitleCell
                            marketplaceStore={promotion.marketplace_store}
                            isActive={promotion.active}
                        />
                        <Divider style={{ margin: 0 }} />
                    </Row>
                </Row>
            </Space>
            {promotion.active ? <ActionButtons promotion={promotion} /> : null}
        </Flex>
    );
};
