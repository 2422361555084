import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/statistics/clicksStatistics/model/types';
import {
    GetSellerClicksStatisticsApiResponse,
    StatisticsClickDto2,
} from '@Shared/api/rtkQueryApis/sellerStatistics/SellerStatisticsApi';
import { ProductNameCell, MPTitleCell } from '@Features/shared/TableCells';
import { formatDate } from '@Shared/utils';

type StatisticsTableProps = {
    data: GetSellerClicksStatisticsApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text, Link } = Typography;

const columns = [
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        width: 110,
        render: (_: unknown, click: StatisticsClickDto2) => formatDate(click.date),
    },
    {
        title: 'Магазин на маркетплейсе',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        render: (_: unknown, click: StatisticsClickDto2) => (
            <MPTitleCell marketplaceStore={click.product?.marketplace_store} />
        ),
    },
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        render: (_: unknown, click: StatisticsClickDto2) => (
            <ProductNameCell
                showSku
                product={click.product}
            />
        ),
    },
    {
        title: 'Площадка паблишера',
        dataIndex: 'source',
        key: 'source',
        render: (_: unknown, commission: StatisticsClickDto2) => (
            <Link
                target="_blank"
                href={commission?.source?.source_url || ''}
            >
                {commission?.source?.source_type?.title || ''}
            </Link>
        ),
    },
    {
        title: 'Подтвержденные клики',
        dataIndex: 'clicks_approved',
        key: 'clicks_approved',
        width: 160,
        render: (_: unknown, click: StatisticsClickDto2) => click.clicks_approved,
    },
    {
        title: 'Все клики',
        dataIndex: 'clicks',
        key: 'clicks',
        width: 140,
        render: (_: unknown, click: StatisticsClickDto2) => click.clicks,
    },
];

const StatisticsTable = ({ data, isLoading, setFilterObject }: StatisticsTableProps) => {
    return (
        <Table
            scroll={{ x: '1150px' }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>У вас нет данных для отображения статистики</Text>,
                }),
            }}
        />
    );
};

export default StatisticsTable;
