import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { CommissionDto } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { formatPrice } from '@Shared/utils/formatPrice';
import { CommissionStatusEnum } from '@Shared/api/rtkQueryApis/sellerCommissions';

const { Text } = Typography;

const CommissionCell = ({ commission }: { commission: CommissionDto }) => {
    const {
        token: { colorTextTertiary, colorText },
    } = theme.useToken();

    return (
        <Text style={{ color: commission?.status === CommissionStatusEnum.Declined ? colorTextTertiary : colorText }}>
            {formatPrice({
                price: commission?.total_fee || 0,
                currency: commission?.order_product?.currency || {},
            })}
        </Text>
    );
};

export default CommissionCell;
