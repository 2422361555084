import { CommissionStatusEnum } from '@Shared/api/rtkQueryApis/sellerCommissions';

export function getCpcCampaignStatusBadgeType(status: CommissionStatusEnum) {
    let type = 'default';
    switch (status) {
        case CommissionStatusEnum.Approved:
            type = 'success';
            break;
        case CommissionStatusEnum.Pending:
            type = 'warning';
            break;
        case CommissionStatusEnum.Declined:
            type = 'default';
            break;
    }

    return type;
}
