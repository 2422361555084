import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, QuestionTooltip } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/statistics/cpcStatistics/model/types';
import {
    GetPublisherCommissionsStatisticsApiResponse,
    StatisticsCommissionDto,
} from '@Shared/api/rtkQueryApis/publisherStatistics/PublisherStatisticsApi';
import { ProductNameCell } from '@Features/shared/TableCells';

type StatisticsTableProps = {
    data: GetPublisherCommissionsStatisticsApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text, Link } = Typography;

const columns = [
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        // width: 300,
        render: (_: unknown, commission: StatisticsCommissionDto) => (
            <ProductNameCell
                showSku
                product={commission.product}
            />
        ),
    },
    {
        title: 'Ссылка на МП',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        render: (_: unknown, commission: StatisticsCommissionDto) => (
            <Link
                target="_blank"
                href={commission?.product?.external_link || ''}
            >
                {commission?.product?.external_link || ''}
            </Link>
        ),
    },
    {
        title: (
            <>
                eCPC &nbsp;
                <QuestionTooltip title="Эффективная стоимость клика" />
            </>
        ),
        width: 120,
        dataIndex: 'ecpc',
        key: 'ecpc',
        render: (_: unknown, commission: StatisticsCommissionDto) => commission.ecpc || '',
    },
    {
        title: 'Коэффициент конверсии (CR)',
        dataIndex: 'cr',
        key: 'cr',
        width: 150,
        render: (_: unknown, commission: StatisticsCommissionDto) => `${(commission?.cr || 0) * 100} %`,
    },
    {
        title: 'Переходы',
        dataIndex: 'clicks',
        key: 'clicks',
        width: 140,
        render: (_: unknown, commission: StatisticsCommissionDto) => commission.clicks,
    },
    {
        title: 'Конверсии',
        dataIndex: 'commissions',
        key: 'commissions',
        width: 150,
        render: (_: unknown, commission: StatisticsCommissionDto) => commission.commissions,
    },
    {
        title: 'Сумма комиссий',
        dataIndex: 'amount',
        key: 'amount',
        width: 160,
        render: (_: unknown, commission: StatisticsCommissionDto) => `${commission.commission_sum} ₽`,
    },
];

const StatisticsTable = ({ data, isLoading, setFilterObject }: StatisticsTableProps) => {
    return (
        <Table
            scroll={{ x: '1250px' }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            summary={() =>
                data?.items?.length ? (
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                            <Text strong>Сумма всего</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} />
                        <Table.Summary.Cell index={2} />
                        <Table.Summary.Cell index={3} />
                        <Table.Summary.Cell index={4}>
                            <Text strong>{data?.meta?.clicks || 0}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                            <Text strong>{data?.meta?.commissions || 0}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                            <Text strong>{data?.meta?.commission_sum || 0} ₽</Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                ) : null
            }
            locale={{
                emptyText: renderEmpty({
                    content: <Text>У вас нет данных для отображения статистики</Text>,
                }),
            }}
        />
    );
};

export default StatisticsTable;
