import { ColumnsType } from 'antd/es/table';
import { TableProps } from 'antd/lib';
import { useMemo, Dispatch, SetStateAction } from 'react';
import { SortOrder } from 'antd/es/table/interface';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, Grid, QuestionTooltip } from '@Shared/ui';
import { type ProductCampaignDto, GetProductsListWithLinksApiResponse } from '@Shared/api/full';
import { PriceCell, ProductNameCell } from '@Features/shared/TableCells/products';
import { FilterObjectType } from '@Features/publisher/products/cpa/model/types';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { SortOrderEnum } from '@Shared/api/rtkQueryApis/publisherSources';

import { FavoriteCell, TrackingLinksCell } from './cells';

import './styles.scss';

const { Text } = Typography;
const { useBreakpoint } = Grid;

type ProductCatalogTableProps = {
    data: GetProductsListWithLinksApiResponse | undefined;
    isProductsLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    setCurrentProductId: (arg1: string) => void;
    setDrawerOpen: (arg1: boolean) => void;
    filterObject: FilterObjectType;
};

const mapTableOrder = {
    [SortOrderEnum.Desc]: 'descend',
    [SortOrderEnum.Asc]: 'ascend',
};

const ProductCatalogTable = ({
    data,
    isProductsLoading,
    setFilterObject,
    setCurrentProductId,
    setDrawerOpen,
    filterObject,
}: ProductCatalogTableProps) => {
    const { data: user } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();

    const handleColumnSort = (field?: string, order?: SortOrderEnum) => {
        setFilterObject((prevFilterObject) => ({
            ...prevFilterObject,
            sortField: field,
            sortOrder: order,
        }));
    };

    const tableOrder = {
        descend: SortOrderEnum.Desc,
        ascend: SortOrderEnum.Asc,
    };

    const handleChangeTable: TableProps<ProductCampaignDto>['onChange'] = (_p, _f, sorter) => {
        if (sorter && !Array.isArray(sorter)) {
            const currentOrder = sorter?.order ? tableOrder[sorter.order] : undefined;
            handleColumnSort(sorter?.field as string, currentOrder);
        }
    };

    const columns: ColumnsType<ProductCampaignDto> = useMemo(
        () => [
            {
                width: 48,
                render: (_: unknown, product) => (
                    <FavoriteCell
                        isFavorite={product?.favorite || false}
                        productId={product.id}
                        storeId={
                            product?.product?.marketplace_store?.store?.id ||
                            product.product?.marketplace_store?.marketplace?.id
                        }
                    />
                ),
            },
            {
                title: 'Название товара',
                dataIndex: 'title',
                key: 'title',
                width: 260,
                ellipsis: true,
                render: (_: unknown, { product }) => <ProductNameCell product={product} />,
            },
            {
                title: (
                    <>
                        Стоимость &nbsp;
                        <QuestionTooltip
                            title="Цена товара на маркетплейсе может отличаться.
                        Вы получите комиссию от той цены, по которой товар будет куплен."
                        />
                    </>
                ),
                dataIndex: 'price',
                key: 'price',
                render: (_: unknown, { product }) => <PriceCell product={product} />,
            },
            {
                title: 'Комиссия',
                dataIndex: 'feeValue',
                key: 'feeValue',
                sorter: true,
                defaultSortOrder:
                    filterObject.sortField === 'feeValue' && filterObject?.sortOrder
                        ? (mapTableOrder[filterObject.sortOrder] as SortOrder)
                        : undefined,
                render: (_: unknown, productCampaign) => (
                    <>{Math.floor((productCampaign?.fee_setting?.fee_value || 0) * 100)}%</>
                ),
            },
            {
                title: 'Категория',
                dataIndex: 'category',
                key: 'category',
                render: (_, { product }) => <>{product?.product_category?.title || ''}</>,
            },
            {
                title: 'Магазин',
                key: 'store',
                render: (_: unknown, { product }) => product?.marketplace_store?.store?.title,
            },
            {
                title: 'Маркетплейс',
                key: 'marketplace',
                render: (_: unknown, { product }) => product?.marketplace_store?.marketplace?.title,
            },
            {
                title: 'Партнерская ссылка',
                dataIndex: 'tracking_link',
                key: 'tracking_link',
                width: breakpoints.sm ? 265 : 150,
                fixed: 'right',
                render: (_: unknown, productCampaign) => <TrackingLinksCell productCampaign={productCampaign} />,
            },
        ],
        [breakpoints, filterObject],
    );

    return (
        <Table<ProductCampaignDto>
            dataSource={data?.items?.map((item) => ({ ...item, key: item.id }))}
            loading={isProductsLoading}
            className="product-campaign-table"
            columns={columns}
            rowKey="id"
            scroll={{ x: '1300px' }}
            onChange={handleChangeTable}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            rowClassName={(record) => (record.favorite ? 'product-campaign-table__favorite' : '')}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setCurrentProductId(record.id);
                        setDrawerOpen(true);
                        sendYmEvent({
                            name: 'click_item',
                            o1: 'account;publisher;catalog',
                            o2: 'pay for order',
                            programId: record.product?.id || '',
                            publisherId: user?.publisher?.id,
                            keycloakId: user?.mitgo,
                        });
                    },
                };
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>У вас пока нет доступных продуктов для продвижения</Text>,
                }),
            }}
        />
    );
};

export default ProductCatalogTable;
