import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Row, Space, Typography, Col } from '@Shared/ui';
import { CpcCommissionsTableBlock } from '@Features/seller/finances/cpcCommissions';
import { InfoInformationCircle } from '@Shared/assets/images/icons';

const { Text, Link } = Typography;

const CpcCommissionsTab = () => {
    const {
        token: { colorBgLayout, padding },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={10}
            >
                <Text style={{ fontSize: 16 }}>
                    Здесь отображаются все ваши комиссии по рекламным кампаниям с типом &quot;Оплата за клик&quot;. По
                    любым вопросам обращайтесь в{' '}
                    <Link
                        href={`mailto:${process.env.SUPPORT_EMAIL}`}
                        style={{ fontSize: 16 }}
                    >
                        поддержку
                    </Link>
                    .
                </Text>

                <Row
                    wrap={false}
                    align="middle"
                    style={{
                        gap: padding,
                        backgroundColor: colorBgLayout,
                        padding: '10px 20px',
                        borderRadius: 12,
                    }}
                >
                    <Col>
                        <Icon component={() => <InfoInformationCircle />} />
                    </Col>
                    <Col>
                        Комиссии подтверждаются автоматически по истечении 7 дней. Этот срок мы оставляем для того,
                        чтобы в отчетах по внешнему трафику успели отобразиться все заказы, совершенные при переходе по
                        партнерским ссылкам.
                    </Col>
                </Row>
            </Space>

            <Row>
                <CpcCommissionsTableBlock />
            </Row>
        </Space>
    );
};
export default CpcCommissionsTab;
