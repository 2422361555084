import { MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Row, Button, Grid } from '@Shared/ui';
import { type ProductCampaignDto } from '@Shared/api/rtkQueryApis/publisherSources';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { useClipboard } from '@Shared/hooks';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { useBreakpoint } = Grid;

const TrackingLinksCell = ({ productCampaign }: { productCampaign: ProductCampaignDto }) => {
    const { copyTextToClipboard } = useClipboard();
    const { data: user } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();

    const {
        token: { borderRadiusLG },
    } = theme.useToken();

    const handleCopyText = (e: MouseEvent<HTMLElement>, link: string, message: string, isFullLink: boolean) => {
        e.stopPropagation();
        copyTextToClipboard(link || '', message);
        sendYmEvent({
            name: 'click_copy_catalog_item_link',
            o1: 'account;publisher;catalog',
            o2: 'pay for order',
            o4: isFullLink ? 'full link' : 'short link',
            publisherId: user?.publisher?.id,
            keycloakId: user?.mitgo,
            sendboxId: link ? new URL(link)?.pathname.split('/')[2] : '',
        });
    };

    return (
        <Row
            style={{ gap: 12 }}
            id="joyride-products-external-link"
        >
            <Button
                size="small"
                style={{ borderRadius: borderRadiusLG, width: breakpoints.sm ? 'auto' : '100%' }}
                iconPosition="end"
                disabled={!productCampaign?.tracking_link}
                onClick={(e) =>
                    handleCopyText(
                        e,
                        productCampaign?.tracking_link || '',
                        'Полная партнерская ссылка успешно скопирована',
                        true,
                    )
                }
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            >
                Полная
            </Button>
            <Button
                size="small"
                iconPosition="end"
                style={{ borderRadius: borderRadiusLG, width: breakpoints.sm ? 'auto' : '100%' }}
                type="primary"
                disabled={!productCampaign?.tracking_short_link}
                onClick={(e) =>
                    handleCopyText(
                        e,
                        productCampaign?.tracking_short_link || '',
                        'Короткая партнерская ссылка успешно скопирована',
                        false,
                    )
                }
                icon={<Icon component={() => <CopyItemLeftTop />} />}
            >
                Короткая
            </Button>
        </Row>
    );
};

export default TrackingLinksCell;
