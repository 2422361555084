import { Layout, theme } from 'antd';
import Icon from '@ant-design/icons';

import { Typography, Row, Col, Flex, Spin, Grid } from '@Shared/ui';
import { SellerImg, WebmasterImg, LogoShort, Logo } from '@Shared/assets/images/';
import WelcomeRoleCard from '@Features/welcome/WelcomeRoleCard/WelcomeRoleCard';
import { useGetTheUserInformationQuery, usePostAppApiUserSetRolesMutation } from '@Shared/api/rtkQueryApis/user';
import { sendBusinessYmReachGoal, sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useSendPageViewEvent } from '@Shared/hooks';

import './styles.scss';

const { Content, Sider, Header } = Layout;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const SITE_NAME = 'Takprodam';
const WelcomePage = () => {
    const breakpoints = useBreakpoint();
    const [postUser, { isLoading }] = usePostAppApiUserSetRolesMutation();
    const {
        token: { colorBgLayout, paddingMD, colorTextLightSolid },
    } = theme.useToken();

    useSendPageViewEvent('account');

    const { data: user } = useGetTheUserInformationQuery();

    const handleSelectRole = async (role: string) => {
        const isSeller = role === 'is_seller';

        sendYmEvent({
            name: 'click_choose_role',
            o1: 'account',
            o2: 'registration',
            o4: isSeller ? 'advertiser' : 'publisher',
            keycloakId: user?.mitgo,
        });

        try {
            await postUser({ userRolesDto: { [role]: true } }).unwrap();

            sendYmEvent({
                name: 'send_registration_success',
                o1: isSeller ? 'account;advertiser' : 'account;publisher',
                o2: 'registration',
                o4: isSeller ? 'advertiser' : 'publisher',
                keycloakId: user?.mitgo,
            });

            sendBusinessYmReachGoal(
                isSeller ? 'send_registration_success_advertiser' : 'send_registration_success_publisher',
            );
        } catch (error) {
            console.error(error);
        }
    };

    if (isLoading) {
        return (
            <Spin
                fullscreen
                size="large"
            />
        );
    }

    return (
        <Layout className="app-layout-container">
            {breakpoints.md ? (
                <Sider
                    trigger={null}
                    width={256}
                >
                    <Row style={{ padding: paddingMD, gap: '8px' }}>
                        <Icon component={() => <LogoShort />} />
                        <Text style={{ color: colorTextLightSolid, opacity: 0.85 }}>{SITE_NAME}</Text>
                    </Row>
                    <Col className="welcome-description">
                        <Title
                            level={3}
                            className="welcome-description-title"
                            style={{ color: colorTextLightSolid }}
                        >
                            Добро пожаловать в Takprodam
                        </Title>
                        <Text style={{ color: colorTextLightSolid, opacity: 0.65 }}>Выберите свою роль</Text>
                    </Col>
                </Sider>
            ) : null}
            <Content style={{ background: colorBgLayout, overflowY: 'auto' }}>
                {breakpoints.md ? (
                    <Row className="welcome-header" />
                ) : (
                    <Header style={{ height: 60, display: 'flex', alignItems: 'center' }}>
                        <Icon component={() => <Logo />} />
                    </Header>
                )}
                <Row
                    justify="center"
                    align="middle"
                    style={{ padding: `0 ${paddingMD}px`, flexDirection: 'column' }}
                >
                    {!breakpoints.md ? (
                        <Title
                            level={3}
                            style={{ textAlign: 'center' }}
                        >
                            Добро пожаловать в Takprodam
                        </Title>
                    ) : null}
                    <Title
                        className="welcome-content-title"
                        level={3}
                    >
                        Выберите свою роль
                    </Title>
                </Row>
                <Flex
                    justify="center"
                    align="middle"
                    gap={'16px'}
                    vertical={!breakpoints.lg}
                    style={{ alignItems: 'center' }}
                >
                    <WelcomeRoleCard
                        title="Продавец"
                        description={`Продвигайте ваши товары и привлекайте новых лояльных
                            покупателей, сотрудничая с лучшими вебмастерами`}
                        imageSrc={SellerImg}
                        onSelect={() => handleSelectRole('is_seller')}
                    />
                    <WelcomeRoleCard
                        title="Вебмастер"
                        description={`Повысьте привлекательность и прибыльность ваших
                            интернет-проектов, сотрудничая с лучшими продавцами`}
                        imageSrc={WebmasterImg}
                        onSelect={() => handleSelectRole('is_publisher')}
                    />
                </Flex>
            </Content>
        </Layout>
    );
};

export default WelcomePage;
