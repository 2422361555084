import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        publisherProductStatistic: build.query<PublisherProductStatisticApiResponse, PublisherProductStatisticApiArg>({
            query: () => ({ url: '/api/publisher/v1/statistic/product/' }),
        }),
        getPublisherClicksStatistics: build.query<
            GetPublisherClicksStatisticsApiResponse,
            GetPublisherClicksStatisticsApiArg
        >({
            query: (queryArg) => ({
                url: '/api/publisher/v1/statistics/clicks/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    search_string: queryArg.searchString,
                    payment_type: queryArg.paymentType,
                },
            }),
        }),
        getPublisherCommissionsStatistics: build.query<
            GetPublisherCommissionsStatisticsApiResponse,
            GetPublisherCommissionsStatisticsApiArg
        >({
            query: (queryArg) => ({
                url: '/api/publisher/v1/statistics/commissions/',
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    search_string: queryArg.searchString,
                    payment_type: queryArg.paymentType,
                    source_types: queryArg.sourceTypes,
                    has_commissions: queryArg.hasCommissions,
                },
            }),
        }),
        getPublisherStatistics: build.query<GetPublisherStatisticsApiResponse, GetPublisherStatisticsApiArg>({
            query: () => ({ url: '/api/publisher/v1/statistics/' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherStatisticsApi };
export type PublisherProductStatisticApiResponse = /** status 200 Resource response */ ProductStatisticListDto;
export type PublisherProductStatisticApiArg = void;
export type GetPublisherClicksStatisticsApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: StatisticsClickDto[];
};
export type GetPublisherClicksStatisticsApiArg = {
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum;
    dateFrom?: string | null;
    dateTo?: string | null;
    searchString?: string | null;
    paymentType?: PaymentTypeEnum;
};
export type GetPublisherCommissionsStatisticsApiResponse = /** status 200 Paginated response */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: StatisticsCommissionDto[];
};
export type GetPublisherCommissionsStatisticsApiArg = {
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum;
    dateFrom?: string | null;
    dateTo?: string | null;
    searchString?: string | null;
    paymentType?: PaymentTypeEnum;
    sourceTypes?: {
        ''?: number[];
    };
    hasCommissions?: boolean;
};
export type GetPublisherStatisticsApiResponse = unknown;
export type GetPublisherStatisticsApiArg = void;
export type ProductStatisticDto = {
    title: string;
    external_link: string;
    clicks: number;
    commissions: number;
    amount: number;
};
export type ProductStatisticListDto = {
    items?: ProductStatisticDto[];
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace2 | null;
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type Publisher2 = object;
export type SourceDto3 = {
    id?: string | null;
    publisher?: Publisher2 | null;
    title: string | null;
    status?: SourceStatusEnum | null;
    source_type: number;
    source_content_types?: number[];
    source_url?: string | null;
    comment?: string | null;
};
export type StatisticsClickDto = {
    date: string;
    product?: ProductDto | null;
    source?: SourceDto3 | null;
    clicks?: number;
    clicks_approved?: number;
};
export type StatisticsCommissionDto = {
    product?: ProductDto | null;
    source?: SourceDto3 | null;
    clicks?: number;
    commissions?: number;
    commission_sum?: number;
    cr?: number;
    ecpc?: number;
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum SourceStatusEnum {
    Verification = 'verification',
    Approved = 'approved',
    Declined = 'declined',
}
export enum SortOrderEnum {
    Asc = 'asc',
    Desc = 'desc',
}
export enum PaymentTypeEnum {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
    Cpmc = 'cpmc',
}
export const {
    usePublisherProductStatisticQuery,
    useGetPublisherClicksStatisticsQuery,
    useGetPublisherCommissionsStatisticsQuery,
    useGetPublisherStatisticsQuery,
} = injectedRtkApi;
