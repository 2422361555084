import { PaymentTypeEnum } from '@Shared/api/full';
import { SortOrderEnum } from '@Shared/api/rtkQueryApis/publisherSources';

export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    MARKETPLACES = 'marketplaces[]',
    STORES = 'stores[]',
    CATEGORIES = 'categories[]',
    FAVORITE = 'favorite',
    SEARCH_STRING = 'search_string',
    SORT_FIELD = 'sortField',
    SORT_ORDER = 'sortOrder',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    stores: string[];
    marketplaces: string[];
    categories: string[];
    favorite: boolean;
    search_string: string;
    payment_type?: PaymentTypeEnum;
    sortField?: string;
    sortOrder?: SortOrderEnum;
};

export type OptionType = { value: string; label: string };

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};
