import { FC } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Space, Row, Typography, Divider, Descriptions, Flex, Tag } from '@Shared/ui';
import { PromotionDto3, PromotionTypeEnum2 } from '@Shared/api/rtkQueryApis/sellerPromotions';
import { MPTitleCell } from '@Features/shared/TableCells';
import { formatPrice } from '@Shared/utils/formatPrice';
import { CouponSaleDiscount, ShoppingBagDiscount } from '@Shared/assets/images/icons';

import ActionButtons from './ActionButtons';

import { StatusCell, PeriodCell } from '../personalPromotionsTable/cells';

const { Title, Text } = Typography;

type PromotionDetailsProps = {
    promotion: PromotionDto3;
};

export const PersonalPromotionDetails: FC<PromotionDetailsProps> = ({ promotion }) => {
    const {
        token: { colorBgLayout, borderRadius, padding, paddingLG, colorPrimary, colorSuccess },
    } = theme.useToken();

    const detailsKeyValueData = [
        { key: 'Статус', value: <StatusCell isActive={!!promotion.active} /> },
        { key: 'Период', value: <PeriodCell promotion={promotion} /> },
        {
            key: 'Скидка',
            value: promotion.discount_value
                ? formatPrice({ price: promotion.discount_value, feeType: promotion.discount_type })
                : 0,
        },
        {
            key: 'Промокод',
            value: promotion.coupon ? (
                <Tag
                    color="volcano"
                    style={{ margin: 0, whiteSpace: 'normal' }}
                >
                    {promotion.coupon}
                </Tag>
            ) : null,
        },
    ].filter((keyValue) => keyValue.value);

    const promotionTypesMap = {
        [PromotionTypeEnum2.Coupon]: {
            title: 'Промокод',
            titleColor: colorPrimary,
            icon: <Icon component={() => <CouponSaleDiscount style={{ color: colorPrimary }} />} />,
        },
        [PromotionTypeEnum2.Sale]: {
            title: 'Акция',
            titleColor: colorSuccess,
            icon: <Icon component={() => <ShoppingBagDiscount style={{ color: colorSuccess }} />} />,
        },
    };

    return (
        <Flex
            gap="middle"
            vertical
            justify="space-between"
            style={{ height: '100%' }}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={20}
            >
                <Space
                    direction="vertical"
                    style={{ width: '100%' }}
                >
                    <Row style={{ gap: 8 }}>
                        {promotionTypesMap[promotion.type].icon}
                        <Text style={{ color: promotionTypesMap[promotion.type].titleColor }}>
                            {promotionTypesMap[promotion.type].title}
                        </Text>
                    </Row>

                    <Title
                        level={4}
                        style={{ margin: 0 }}
                    >
                        {promotion?.title || ''}
                    </Title>
                </Space>
                <div dangerouslySetInnerHTML={{ __html: promotion?.description || '' }}></div>
                <Row
                    style={{
                        background: colorBgLayout,
                        padding: `${padding}px ${paddingLG}px`,
                        borderRadius: borderRadius,
                    }}
                >
                    <Descriptions
                        column={1}
                        title={
                            <Title
                                level={5}
                                style={{ marginTop: 0 }}
                            >
                                Детали акции
                            </Title>
                        }
                    >
                        {detailsKeyValueData.map((item, index) => (
                            <Descriptions.Item
                                label={item.key}
                                key={index}
                                contentStyle={{ justifyContent: 'flex-end' }}
                            >
                                {item.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Row>
                <Row style={{ flexDirection: 'column' }}>
                    <Title
                        level={5}
                        style={{ marginTop: 0 }}
                    >
                        Магазин
                    </Title>
                    <Row style={{ gap: 16 }}>
                        <Divider style={{ margin: 0 }} />
                        <MPTitleCell
                            marketplaceStore={promotion.marketplace_store}
                            isActive={promotion.active}
                        />
                        <Divider style={{ margin: 0 }} />
                    </Row>
                </Row>
            </Space>
            <ActionButtons promotion={promotion} />
        </Flex>
    );
};
