import { FC, MouseEvent } from 'react';
import { theme } from 'antd';
import Icon from '@ant-design/icons';

import { Space, Row, Typography, Divider, Descriptions, Flex, Col, Button } from '@Shared/ui';
import { PromotionProductDto } from '@Shared/api/rtkQueryApis/publisherPromotions';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
import { CopyCardValue } from '@Features/shared';
import { useClipboard } from '@Shared/hooks';
import { formatPrice } from '@Shared/utils/formatPrice';
import { FAKE_LINK } from '@Shared/constants';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

import { MPTitleCell, PeriodCell } from '../promotionsTable/cells';

const { Text, Title } = Typography;

type PromotionDetailsProps = {
    promotionProduct: PromotionProductDto;
};

export const PromotionDetails: FC<PromotionDetailsProps> = ({ promotionProduct }) => {
    const { copyTextToClipboard } = useClipboard();
    const { data: user } = useGetTheUserInformationQuery();

    const {
        token: { colorPrimary, borderRadius, padding, colorFillAlter, colorTextTertiary },
    } = theme.useToken();

    const YmCopyLinkEvent = (isFullLink: boolean) => {
        sendYmEvent({
            name: 'click_copy_promotion_item_link',
            o1: 'account;publisher;promotions and promocodes',
            o2: 'from marketplace',
            o4: isFullLink ? 'full link' : 'short link',
            publisherId: user?.publisher?.id,
            keycloakId: user?.mitgo,
        });
    };

    const detailsKeyValueData = [
        { key: 'Период', value: <PeriodCell promotion={promotionProduct.promotion} /> },
        {
            key: 'Стоимость',
            value: (
                <Row style={{ flexDirection: 'column', alignItems: 'end' }}>
                    <Text>
                        {' '}
                        {formatPrice({
                            price: promotionProduct.price_discount || 0,
                            currency: promotionProduct?.product?.currency || {},
                        })}
                    </Text>
                    <Text
                        type="secondary"
                        style={{ fontSize: 12 }}
                    >
                        Без скидки{' '}
                        {formatPrice({
                            price: promotionProduct.price || 0,
                            currency: promotionProduct?.product?.currency || {},
                        })}
                    </Text>
                </Row>
            ),
        },
        {
            key: 'Скидка',
            value: promotionProduct?.price_percent
                ? // eslint-disable-next-line max-len
                  `${promotionProduct?.price_percent}% / ${formatPrice({ price: Math.round(((promotionProduct?.price || 0) - (promotionProduct?.price_discount || 0)) * 100) / 100, currency: promotionProduct?.product?.currency || {} })}`
                : null,
        },
        {
            key: 'Ваша комиссия:',
            value: (
                <Text style={{ color: colorPrimary }}>
                    {promotionProduct.fee_setting?.fee_value
                        ? `${promotionProduct.fee_setting?.fee_value * 100}%`
                        : null}
                </Text>
            ),
        },
    ].filter((keyValue) => keyValue.value);

    const handleCopyText = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        copyTextToClipboard(promotionProduct?.legal_text || '', 'Данные для маркировки рекламы успешно скопированы');
        sendYmEvent({
            name: 'click_copy_promotion_item_erid_data',
            o1: 'account;publisher;promotions and promocodes',
            o2: 'from marketplace',
            publisherId: user?.publisher?.id,
            keycloakId: user?.mitgo,
            accountType: 'business',
        });
    };

    return (
        <Flex
            gap="middle"
            vertical
            justify="space-between"
            style={{ height: '100%' }}
        >
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={20}
            >
                <Title
                    level={4}
                    style={{ margin: 0 }}
                >
                    {promotionProduct?.product?.title || ''}
                </Title>
                <Row style={{ gap: 8 }}>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Полная партнерская ссылка
                    </Title>
                    <CopyCardValue
                        buttonProps={{ type: 'primary' }}
                        cardValue={promotionProduct?.tracking_link || FAKE_LINK}
                        className={promotionProduct?.tracking_link ? '' : 'blurred-link-block'}
                        copyMessageText="Полная партнерская ссылка успешно скопирована"
                        ymEvent={() => YmCopyLinkEvent(true)}
                    />
                </Row>
                <Row style={{ gap: 8 }}>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Короткая партнерская ссылка
                    </Title>
                    <CopyCardValue
                        buttonProps={{ type: 'primary' }}
                        cardValue={promotionProduct?.tracking_short_link || FAKE_LINK}
                        className={promotionProduct?.tracking_short_link ? '' : 'blurred-link-block'}
                        copyMessageText="Короткая партнерская ссылка успешно скопирована"
                        ymEvent={() => YmCopyLinkEvent(false)}
                    />
                </Row>
                <Row>
                    <Descriptions
                        column={1}
                        title={
                            <Title
                                level={5}
                                style={{ marginTop: 0 }}
                            >
                                Условия
                            </Title>
                        }
                    >
                        {detailsKeyValueData.map((item, index) => (
                            <Descriptions.Item
                                label={item.key}
                                key={index}
                                contentStyle={{ justifyContent: 'flex-end' }}
                            >
                                {item.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Row>
                <Row style={{ flexDirection: 'column' }}>
                    <Title
                        level={5}
                        style={{ marginTop: 0 }}
                    >
                        Магазин
                    </Title>
                    <Row style={{ gap: 16 }}>
                        <Divider style={{ margin: 0 }} />
                        <MPTitleCell marketplaceStore={promotionProduct?.promotion?.marketplace_store} />
                        <Divider style={{ margin: 0 }} />
                    </Row>
                </Row>
                <Row style={{ gap: 8 }}>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Данные для маркировки рекламы
                    </Title>
                    <Row
                        wrap={false}
                        justify="space-between"
                        align="middle"
                        style={{
                            background: colorFillAlter,
                            padding: padding,
                            borderRadius: borderRadius,
                            width: '100%',
                        }}
                    >
                        <Text>{promotionProduct?.legal_text || 'Нет данных'}</Text>
                        <Col style={{ width: 40 }}>
                            <Button
                                type="primary"
                                ghost
                                disabled={!promotionProduct?.legal_text}
                                onClick={handleCopyText}
                                icon={<Icon component={() => <CopyItemLeftTop />} />}
                            />
                        </Col>
                    </Row>
                    <Text style={{ color: colorTextTertiary }}>
                        Укажите эти данные в публикации для соблюдения закона о Рекламе
                    </Text>
                </Row>
            </Space>
        </Flex>
    );
};
