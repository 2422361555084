import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/finances/cpcCommissions/model/types';
import { formatDate } from '@Shared/utils';
import {
    GetSellerCommissionsListApiResponse,
    CommissionDto,
    CommissionStatusEnum,
} from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { MPTitleCell } from '@Features/shared/TableCells';

import { CommissionCell, StatusCell, ClickCountCell } from './cells';

type CpcCommissionsTableProps = {
    data: GetSellerCommissionsListApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text, Link } = Typography;

const columns = [
    {
        title: 'Дата',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        width: 110,
        render: (_: unknown, { cpc_commission_detail }: CommissionDto) => (
            <>{formatDate(cpc_commission_detail?.date || '')}</>
        ),
    },
    {
        title: 'Магазин',
        key: 'store',
        render: (_: unknown, commission: CommissionDto) => (
            <MPTitleCell
                marketplaceStore={commission.marketplace_store}
                isActive={commission.status !== CommissionStatusEnum.Declined}
            />
        ),
    },
    {
        title: 'Ссылка на МП',
        key: 'external_link',
        // ellipsis: true,
        render: (_: unknown, { click_product }: CommissionDto) => (
            <Link
                href={click_product?.external_link || ''}
                target="_blank"
                style={{
                    textOverflow: 'ellipsis',
                    width: '100%',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {click_product?.external_link || ''}
            </Link>
        ),
    },
    {
        title: 'Площадка паблишера',
        key: 'source',
        render: (_: unknown, { source }: CommissionDto) => {
            const isAnother = source?.source_type?.title === 'Другое';
            const isNickname = source?.source_url?.startsWith?.('@');
            if (isNickname && isAnother) {
                return `${source?.source_type?.title}: ${source?.source_url}`;
            }
            return (
                <Link
                    href={source?.source_url || ''}
                    target="_blank"
                >
                    {source?.source_type?.title || ''}
                </Link>
            );
        },
    },
    {
        title: 'Кол-во кликов',
        dataIndex: 'clickCount',
        key: 'clickCount',
        width: 150,
        render: (_: unknown, commission: CommissionDto) => <ClickCountCell commission={commission} />,
    },
    {
        title: 'Комиссия',
        dataIndex: 'total_fee',
        key: 'total_fee',
        width: 120,
        render: (_: unknown, commission: CommissionDto) => <CommissionCell commission={commission} />,
    },
    // {
    //     title: (
    //         <>
    //             eCPC &nbsp;
    //             <QuestionTooltip title="Эффективная стоимость клика" />
    //         </>
    //     ),
    //     width: 120,
    //     dataIndex: 'ecpc',
    //     key: 'ecpc',
    //     render: (_: unknown, commission: CommissionDto) => <EcpcCell commission={commission} />,
    // },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        width: 160,
        render: (_: unknown, commission: CommissionDto) => <StatusCell status={commission.status} />,
    },
];

const CpcCommissionsTable = ({ data, isLoading, setFilterObject }: CpcCommissionsTableProps) => {
    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items}
            rowKey="id"
            columns={columns}
            // showHeader={!!data?.items?.length}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: (
                        <Text>
                            Сейчас у вас нет неподтвержденных комиссий по рекламным кампаниям с типом &quot;Оплата за
                            клик&quot;. <br /> Если вы считаете, что это ошибка, свяжитесь с{' '}
                            <Link href={`mailto:${process.env.SUPPORT_EMAIL}`}> Поддержкой</Link>.
                        </Text>
                    ),
                }),
            }}
        />
    );
};

export default CpcCommissionsTable;
