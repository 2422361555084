import { Alert, Button, Grid } from '@Shared/ui';
import { InfoInformationCircle } from '@Shared/assets/images/icons';
import { usePatchAppPrivateSellerCampaignSetvisibilityMutation } from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation } from '@Shared/api/rtkQueryApis/sellerPromotions';

import { FullVisibilityAlertEntityType } from './constants';

const { useBreakpoint } = Grid;

export const FullVisibilityAlert = ({
    entityType = FullVisibilityAlertEntityType.CAMPAIGN,
    hasButton = true,
    entityIdentificator,
    isButtonDisabled,
}: {
    entityType?: FullVisibilityAlertEntityType;
    isButtonDisabled?: boolean;
    hasButton?: boolean;
    entityIdentificator?: string;
}) => {
    const breakpoints = useBreakpoint();
    const usePatchVisibilityMutation = {
        [FullVisibilityAlertEntityType.CAMPAIGN]: usePatchAppPrivateSellerCampaignSetvisibilityMutation,
        [FullVisibilityAlertEntityType.PROMOTION_PRODUCTS]:
            usePatchAppPrivateSellerPromotionSetpromotionproductvisibilityMutation,
    }[entityType];
    const [patchFullVisibility, { isLoading }] = usePatchVisibilityMutation();

    const handleSetFullVisibility = async () => {
        const visibilitySettings = {
            select_all: true,
            visible: true,
        };
        const arg =
            entityType === FullVisibilityAlertEntityType.CAMPAIGN
                ? {
                      slug: entityIdentificator,
                      setVisibilityDto: visibilitySettings,
                  }
                : {
                      id: entityIdentificator,
                      setPromotionProductVisibilityDto: visibilitySettings,
                  };
        await patchFullVisibility({
            ...arg,
            notificationOptions: { successMessage: 'Действие успешно', errorMessage: 'Произошла ошибка' },
        }).unwrap();
    };

    return (
        <Alert
            message="Для максимальной эффективности рекламы рекомендуем показывать паблишерам все доступные товары."
            type="info"
            showIcon
            icon={
                <InfoInformationCircle
                    style={{
                        marginRight: 12,
                        display: breakpoints.sm ? 'block' : 'none',
                    }}
                />
            }
            style={{
                border: 'none',
                padding: '12px 20px',
                display: breakpoints.sm ? 'flex' : 'block',
                flexDirection: breakpoints.sm ? 'row' : 'column',
            }}
            {...(hasButton && {
                action: (
                    <Button
                        style={{
                            marginLeft: breakpoints.sm ? 16 : 0,
                            marginTop: breakpoints.sm ? 0 : 16,
                            width: breakpoints.sm ? 'auto' : '100%',
                        }}
                        onClick={handleSetFullVisibility}
                        disabled={isButtonDisabled || isLoading}
                        loading={isLoading}
                        block
                    >
                        Продвигать всё
                    </Button>
                ),
            })}
        />
    );
};
