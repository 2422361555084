import { DraggerProps as _DraggerProps } from 'antd/es/upload';

export enum DraggerErrorType {
    error = 'error',
    warning = 'warning',
}

export type DraggerError = {
    type: DraggerErrorType;
    message: string;
};

export type DraggerProps = _DraggerProps & {
    text?: string;
    hint?: string;
    maxFileSize?: number;
    onError?: (error: DraggerError | null) => void;
    error?: DraggerError | null;
    onRemove: () => void;
};
