import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon, { FileOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Button, Col, Typography, Avatar, Row, Divider, Space } from '@Shared/ui';
import { SourceDto3, ProductCampaignDto } from '@Shared/api/rtkQueryApis/publisherSources';
import { CopyCardValue } from '@Features/shared';
import { CopyItemLeftTop } from '@Shared/assets/images/icons';
// import { CopyItemLeftTop, Star } from '@Shared/assets/images/icons';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';
import { useAppDispatch } from '@Shared/hooks';
import { FAKE_LINK } from '@Shared/constants';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { Text, Title, Link } = Typography;

type ProductDetailsProps = {
    productCampaign: ProductCampaignDto;
    source?: SourceDto3;
};

export const ProductDetails: FC<ProductDetailsProps> = ({ productCampaign, source }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { data: user } = useGetTheUserInformationQuery();
    const {
        token: { colorFillAlter, borderRadius, padding, colorTextTertiary, marginSM },
    } = theme.useToken();

    const handleCopyText = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(productCampaign.legal_text || '');
        sendYmEvent({
            name: 'click_copy_catalog_item_erid_data',
            o1: 'account;publisher;catalog',
            o2: 'pay for click',
            publisherId: user?.publisher?.id,
            keycloakId: user?.mitgo,
            accountType: 'business',
        });
        if (productCampaign.legal_text) {
            dispatch(
                showMessage({
                    componentProps: {
                        type: MessageType.Success,
                        content: 'Данные для маркировки рекламы успешно скопированы',
                    },
                }),
            );
        }
    };

    const YmCopyLinkEvent = (isFullLink: boolean) => {
        sendYmEvent({
            name: 'click_copy_catalog_item_link',
            o1: 'account;publisher;catalog',
            o2: 'pay for click',
            o4: isFullLink ? 'full link' : 'short link',
            publisherId: user?.publisher?.id,
            keycloakId: user?.mitgo,
        });
    };

    return (
        <>
            {/* <Row style={{ marginBottom: 8, gap: 4, alignItems: 'flex-start' }}>
                <Icon component={() => <Star style={{ opacity: '0.45' }} />} />
                <Text>4,5</Text>
                <Text style={{ color: colorTextTertiary }}>| 45 558 оценок</Text>
            </Row> */}
            <Space
                direction="vertical"
                style={{ width: '100%' }}
                size={24}
            >
                <Row
                    gutter={[0, 32]}
                    wrap={false}
                >
                    <Col flex="auto">
                        <Row>
                            <Title
                                style={{ marginTop: 0, marginBottom: 8 }}
                                level={3}
                            >
                                {productCampaign?.product?.title}
                            </Title>
                        </Row>
                    </Col>
                    <Col
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                        flex="80px"
                    >
                        <Avatar
                            shape="square"
                            icon={productCampaign?.product?.image_url ? null : <FileOutlined />}
                            src={productCampaign?.product?.image_url}
                            style={{ float: 'left', marginRight: marginSM }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Title
                        level={5}
                        style={{ marginTop: 0 }}
                    >
                        Полная партнерская ссылка
                    </Title>
                    <CopyCardValue
                        className={productCampaign?.tracking_link ? '' : 'blurred-link-block'}
                        cardValue={productCampaign?.tracking_link || FAKE_LINK}
                        copyMessageText="Полная партнерская ссылка успешно скопирована"
                        buttonProps={{ type: 'primary' }}
                        ymEvent={() => YmCopyLinkEvent(true)}
                    />
                </Row>
                <Row>
                    <Title
                        level={5}
                        style={{ marginTop: 0 }}
                    >
                        Короткая партнерская ссылка
                    </Title>
                    <CopyCardValue
                        className={productCampaign?.tracking_short_link ? '' : 'blurred-link-block'}
                        cardValue={productCampaign?.tracking_short_link || FAKE_LINK}
                        copyMessageText="Короткая партнерская ссылка успешно скопирована"
                        buttonProps={{ type: 'primary' }}
                        ymEvent={() => YmCopyLinkEvent(false)}
                    />
                </Row>
                {productCampaign?.product?.external_link ? (
                    <Row>
                        <Title
                            level={5}
                            style={{ marginTop: 0 }}
                        >
                            Ссылка на товар в МП
                        </Title>
                        <Link
                            href={productCampaign?.product?.external_link || ''}
                            target="_blank"
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {productCampaign.product?.external_link}
                        </Link>
                    </Row>
                ) : null}
                {source ? (
                    <Row style={{ flexDirection: 'column' }}>
                        <Title
                            level={5}
                            style={{ margin: 0 }}
                        >
                            Площадка
                        </Title>
                        <Row>
                            <Divider style={{ margin: 0 }} />
                            <Row
                                align="middle"
                                justify="space-between"
                                style={{ width: '100%' }}
                            >
                                <Text>{source?.title}</Text>
                                <Button
                                    type="link"
                                    onClick={() => navigate('/sources', { state: { initialSourceId: source.id } })}
                                >
                                    Детали
                                </Button>
                            </Row>
                            <Divider style={{ margin: 0 }} />
                        </Row>
                    </Row>
                ) : null}
                <Row style={{ gap: 8 }}>
                    <Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Данные для маркировки рекламы
                    </Title>
                    <Row
                        wrap={false}
                        justify="space-between"
                        align="middle"
                        style={{
                            background: colorFillAlter,
                            padding: padding,
                            borderRadius: borderRadius,
                            width: '100%',
                        }}
                    >
                        <Text>{productCampaign?.legal_text || 'Нет данных'}</Text>
                        <Col style={{ width: 40 }}>
                            <Button
                                type="primary"
                                ghost
                                onClick={handleCopyText}
                                icon={<Icon component={() => <CopyItemLeftTop />} />}
                            />
                        </Col>
                    </Row>
                    <Text style={{ color: colorTextTertiary }}>
                        Укажите эти данные в публикации для соблюдения закона о Рекламе
                    </Text>
                </Row>
            </Space>
        </>
    );
};
