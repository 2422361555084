import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space } from '@Shared/ui';
import {
    useGetSellerCommissionsListQuery,
    PaymentTypeEnum2,
} from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import {
    FilterEnum,
    FilterObjectType,
    DEFAULT_PAGE_SIZE,
    DEFAULT_PAGE,
} from '@Features/seller/finances/cpcCommissions/model/types';
import { useUpdateSearchParams } from '@Shared/hooks';

import CpcCommissionsTable from './cpcCommissionsTable/CpcCommissionsTable';
import CpcCommissionsFilters from './cpcCommissionsFilters/CpcCommissionsFilters';

const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    createdAtFrom: searchParams.get(FilterEnum.CREATED_AT_FROM) || '',
    createdAtTo: searchParams.get(FilterEnum.CREATED_AT_TO) || '',
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    sku: searchParams.get(FilterEnum.SKU) || '',
    marketplaceStoreId: searchParams.get(FilterEnum.MARKETPLACE_STORE_ID) || '',
});

const CpcCommissionsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetSellerCommissionsListQuery({
        page: filterObject.page,
        limit: filterObject.limit,
        cpcCommissionDetailDateFrom: filterObject.createdAtFrom || '',
        cpcCommissionDetailDateTo: filterObject.createdAtTo || '',
        sku: filterObject.sku || '',
        marketplaceStoreId: filterObject.marketplaceStoreId || '',
        paymentType: PaymentTypeEnum2.Cpc,
    });

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    return (
        <Space
            direction="vertical"
            size={24}
            style={{ width: '100%' }}
        >
            <CpcCommissionsFilters
                filterObject={filterObject}
                setFilterObject={setFilterObject}
            />
            <CpcCommissionsTable
                data={data}
                isLoading={isFetching}
                setFilterObject={setFilterObject}
            />
        </Space>
    );
};
export default CpcCommissionsTableBlock;
