export enum FilterEnum {
    LIMIT = 'limit',
    PAGE = 'page',
    SEARCH_STRING = 'search_string',
    HAS_COMMISSIONS = 'has_commissions',
    CREATED_AT_FROM = 'createdAtFrom',
    CREATED_AT_TO = 'createdAtTo',
}

export type FilterObjectType = {
    page: number;
    limit: number;
    has_commissions: boolean;
    search_string: string;
    createdAtFrom: string;
    createdAtTo: string;
};

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE = 1;

export const defaultFilterObject: Partial<FilterObjectType> = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
};
