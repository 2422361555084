import { FileOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Avatar, Typography, Row, Col } from '@Shared/ui';
import { type ProductDto } from '@Shared/api/full';

const { Text } = Typography;

export const ProductNameCell = ({
    product,
    showCategory = false,
    showSku = false,
}: {
    product: ProductDto | null | undefined;
    showCategory?: boolean;
    showSku?: boolean;
}) => {
    const {
        token: { marginSM },
    } = theme.useToken();

    return (
        <Row wrap={false}>
            <Col>
                <Avatar
                    shape="square"
                    icon={product?.image_url ? null : <FileOutlined />}
                    src={product?.image_url}
                    style={{ float: 'left', marginRight: marginSM }}
                />
            </Col>
            <Col>
                <Text
                    strong
                    style={{ whiteSpace: 'normal' }}
                >
                    {product?.title}
                </Text>
                {showCategory && product?.product_category?.title ? (
                    <>
                        <br />
                        <Text type="secondary">{product?.product_category?.title}</Text>
                    </>
                ) : null}
                {showSku && product?.sku ? (
                    <>
                        <br />
                        <Text type="secondary">Арт.: {product?.sku}</Text>
                    </>
                ) : null}
            </Col>
        </Row>
    );
};
