import { useNavigate } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';

import { Button, Card, Grid, Row, Typography } from '@Shared/ui';
import { OnboardingCallBlock } from '@Features/shared';
import { SellerOnboardingStatuses } from '@Shared/model/joyrideTypes';

import { CPCform } from './cpcForm';
import { DeleteProductCampaignModal } from './modals';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const CPCFormCard = () => {
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();

    return (
        <Card>
            <OnboardingCallBlock onboardingStatus={SellerOnboardingStatuses.ONBOARDING_CALL_CPC_SETUP} />
            <Row
                align="top"
                style={{ columnGap: 16, rowGap: 24 }}
                wrap={false}
            >
                <Title
                    level={3}
                    style={{ marginTop: 4 }}
                >
                    Настройка рекламной кампании
                </Title>
                <Button
                    icon={<RollbackOutlined />}
                    iconPosition="end"
                    type="link"
                    onClick={() => navigate(-1)}
                >
                    {breakpoints.lg ? 'Назад' : ''}
                </Button>
            </Row>
            <Paragraph
                type="secondary"
                style={{ marginBottom: 24 }}
            >
                Здесь вы создаете рекламную кампанию с оплатой за клик. <br /> Выберите товары, которые будут
                участвовать в кампании, и укажите цену клика по вашей рекламе. Вы платите только за уникальные клики
                реальных пользователей по активным объявлениям, даже если товар скрыт из каталога. Минимальная цена
                клика — 5 рублей.
            </Paragraph>
            <CPCform />
            <DeleteProductCampaignModal />
        </Card>
    );
};

export default CPCFormCard;
