import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getStoresList: build.query<GetStoresListApiResponse, GetStoresListApiArg>({
            query: () => ({ url: '/api/seller/v1/store/' }),
        }),
        createANewStore: build.mutation<CreateANewStoreApiResponse, CreateANewStoreApiArg>({
            query: (queryArg) => ({ url: '/api/seller/v1/store/', method: 'POST', body: queryArg.storeDto }),
        }),
        viewTheStore: build.query<ViewTheStoreApiResponse, ViewTheStoreApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/` }),
        }),
        putAppPrivateSellerStoreUpdate: build.mutation<
            PutAppPrivateSellerStoreUpdateApiResponse,
            PutAppPrivateSellerStoreUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/store/${queryArg.store}/`,
                method: 'PUT',
                body: queryArg.storeDto2,
            }),
        }),
        removeTheStore: build.mutation<RemoveTheStoreApiResponse, RemoveTheStoreApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/store/${queryArg.store}/`, method: 'DELETE' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerStoresApi };
export type GetStoresListApiResponse = /** status 200 Get stores list */ StoreDtoList;
export type GetStoresListApiArg = void;
export type CreateANewStoreApiResponse = /** status 201 Resource created */ StoreDto3;
export type CreateANewStoreApiArg = {
    storeDto: StoreDto;
};
export type ViewTheStoreApiResponse = /** status 200 Resource response */ StoreDto3;
export type ViewTheStoreApiArg = {
    store: string;
};
export type PutAppPrivateSellerStoreUpdateApiResponse = unknown;
export type PutAppPrivateSellerStoreUpdateApiArg = {
    store: string;
    storeDto2: StoreDto2;
};
export type RemoveTheStoreApiResponse = /** status 204 Resource removed */ void;
export type RemoveTheStoreApiArg = {
    store: string;
};
export type MarketplaceDto2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStoreDto3 = {
    id: string;
    marketplace: MarketplaceDto2;
    store: StoreDto3;
};
export type StoreDto3 = {
    id: string;
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto3[];
    active: boolean;
};
export type StoreDtoList = {
    items?: StoreDto3[];
};
export type StoreDto = {
    title: string;
    comment?: string | null;
    logo?: string | null;
};
export type MarketplaceStoreDto2 = object;
export type StoreDto2 = {
    title: string;
    comment?: string | null;
    logo?: string | null;
    marketplaceStores?: MarketplaceStoreDto2[];
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export const {
    useGetStoresListQuery,
    useCreateANewStoreMutation,
    useViewTheStoreQuery,
    usePutAppPrivateSellerStoreUpdateMutation,
    useRemoveTheStoreMutation,
} = injectedRtkApi;
