import { useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import { UploadOutlined } from '@ant-design/icons';

import { Button, Flex, Form, Typography, Input, Upload, message } from '@Shared/ui';
import { ModalKeys, DrawerKeys } from '@Shared/model/seller/stores/storesPageTypes';
import { uploadFileRequest } from '@Shared/utils/uploadFileRequest';
import { StoreDto3, usePutAppPrivateSellerStoreUpdateMutation } from '@Shared/api/rtkQueryApis/sellerStores';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import type { UploadProps } from 'antd';

const { Title } = Typography;

type StoreDetailsFormProps = {
    setOpen: (arg1: boolean) => void;
    setDrawerContentType: (arg1: DrawerKeys) => void;
    store: StoreDto3 | undefined;
    setModalOpen: (arg1: boolean) => void;
    setModalType: (arg1: ModalKeys) => void;
    setDrawerExtraContent: Dispatch<SetStateAction<ReactNode>>;
    setDrawerTitle: Dispatch<SetStateAction<ReactNode>>;
};

const EditStoreForm = ({
    setOpen,
    store,
    // setModalType,
    // setModalOpen,
    setDrawerExtraContent,
    setDrawerTitle,
    setDrawerContentType,
}: StoreDetailsFormProps) => {
    const [form] = Form.useForm();
    const [updateStore, { isLoading: isUpdating, error: isStoreUpdatingError }] =
        usePutAppPrivateSellerStoreUpdateMutation();

    const handleSubmit = async (formValues: StoreDto3) => {
        try {
            await updateStore({
                storeDto2: formValues,
                notificationOptions: {
                    successMessage: 'Магазин успешно обновлен',
                    errorMessage: 'Ошибка при попытке обновлении магазина',
                },
                store: store?.id || '',
            }).unwrap();
            setOpen(false);
            form.resetFields();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const errors = normalizeErrorsObject(isStoreUpdatingError?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [isStoreUpdatingError, form]);

    useEffect(() => {
        setDrawerTitle(
            <Button
                type="text"
                onClick={() => setDrawerContentType('storeDetails')}
            >
                Назад
            </Button>,
        );
    }, [setDrawerContentType, setDrawerTitle]);

    useEffect(() => {
        setDrawerExtraContent(
            <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isUpdating}
            >
                Сохранить
            </Button>,
        );
    }, [form, isUpdating, setDrawerExtraContent]);

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        if (store) {
            form.setFieldsValue(store);
        }
    }, [form, store]);

    const uploadProps: UploadProps = {
        name: 'file',
        accept: 'image/png, image/svg+xml, image/jpeg',
        customRequest: uploadFileRequest,
        listType: 'picture',
        defaultFileList: [
            {
                uid: store?.logo || '',
                status: 'done',
                url: store?.logo || '',
            },
        ],
        maxCount: 1,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                form.setFieldValue('logo', info.file.response);
            } else if (status === 'removed') {
                form.setFieldValue('logo', null);
            } else if (status === 'error') {
                message.error(`Ошибка загрузки файла ${info.file.name}.`);
            }
        },
    };

    return (
        <>
            <Flex vertical>
                <Form
                    form={form}
                    name="editStore"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    autoComplete="off"
                >
                    <Title
                        style={{ marginBottom: 8 }}
                        level={4}
                    >
                        Редактирование магазина
                    </Title>
                    <Form.Item
                        label="Название магазина"
                        name="title"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                        style={{ marginTop: 20 }}
                    >
                        <Input placeholder="Укажите название" />
                    </Form.Item>
                    <Form.Item
                        label="Логотип"
                        name="logo"
                    >
                        <Upload {...uploadProps}>
                            <Button
                                type="primary"
                                icon={<UploadOutlined />}
                            >
                                Upload
                            </Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Flex>
            {/* <Flex
                vertical
                className="edit-store__delete-button"
            >
                <Button
                    danger
                    onClick={() => {
                        setModalOpen(true);
                        setModalType('deleteStore');
                    }}
                >
                    Удалить магазин
                </Button>
            </Flex> */}
        </>
    );
};

export default EditStoreForm;
