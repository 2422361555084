import { useState } from 'react';
import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Row, List, Typography, Col, Avatar, Badge, Button, Space } from '@Shared/ui';
import { Warning } from '@Shared/assets/images/icons';
import {
    usePatchAppPrivateNotificationMarkasreadMutation,
    useDeleteAppPrivateNotificationDeleteMutation,
    NotificationDto,
} from '@Shared/api/rtkQueryApis/notifications';
import { calculateDateTimeAgo } from '@Shared/utils/dateTime/calculateDateTimeAgo';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const { Text, Paragraph } = Typography;

const NotificationListItem = ({
    notification,
}: {
    notification: NotificationDto;
    isMobile?: boolean;
    isLoading?: boolean;
}) => {
    const {
        token: { colorTextDescription, colorWarningBg, fontSizeSM, fontSize },
    } = theme.useToken();
    const { data: user } = useGetTheUserInformationQuery();
    const [readNotification, { isLoading: isReadingNotification }] = usePatchAppPrivateNotificationMarkasreadMutation();
    const [deleteNotification, { isLoading: isDeletingNotification }] = useDeleteAppPrivateNotificationDeleteMutation();
    const [expanded, setExpanded] = useState(false);

    const handleReadNotification = async () => {
        try {
            await readNotification({
                notification: notification?.id,
            }).unwrap();
            sendYmEvent({
                name: 'click_read_notifications',
                o1: 'account;advertiser;header',
                o2: 'header',
                advertiserId: user?.seller?.id,
                keycloakId: user?.mitgo,
                accountType: 'business',
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeleteNotification = async () => {
        try {
            await deleteNotification({
                notification: notification?.id,
            }).unwrap();
            sendYmEvent({
                name: 'click_delete_notifications',
                o1: 'account;advertiser;header',
                o2: 'header',
                advertiserId: user?.seller?.id,
                keycloakId: user?.mitgo,
                accountType: 'business',
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <List.Item
            key={notification?.id}
            style={{
                borderBottom: '1px solid #f0f0f0',
                padding: '8px 0',
            }}
        >
            <Row
                style={{ gap: 16 }}
                wrap={false}
            >
                <Col>
                    <Badge dot={!notification?.read}>
                        <Avatar
                            shape="square"
                            style={{ backgroundColor: colorWarningBg }}
                            icon={<Icon component={() => <Warning style={{ color: colorTextDescription }} />} />}
                        />
                    </Badge>
                </Col>
                <Col>
                    <Space
                        direction="vertical"
                        size={8}
                    >
                        <Row style={{ flexDirection: 'column' }}>
                            {notification?.subject ? (
                                <Row>
                                    <Text strong>{notification.subject}</Text>
                                </Row>
                            ) : null}
                            {notification?.body && (
                                <Paragraph
                                    type="secondary"
                                    style={{ margin: 0, fontSize: fontSizeSM }}
                                    ellipsis={{
                                        rows: 2,
                                        expandable: 'collapsible',
                                        expanded,
                                        symbol: (
                                            <span style={{ fontSize: fontSize }}>{expanded ? 'Свернуть' : 'Еще'}</span>
                                        ),
                                        onExpand: (_, info) => setExpanded(info.expanded),
                                    }}
                                >
                                    {notification.body}
                                </Paragraph>
                            )}
                        </Row>
                        <Text type="secondary">{calculateDateTimeAgo(notification?.createdAt)} назад</Text>
                        <Row
                            style={{ gap: 8 }}
                            align="middle"
                        >
                            <Button
                                loading={isDeletingNotification}
                                onClick={handleDeleteNotification}
                                size="small"
                            >
                                Удалить
                            </Button>
                            {!notification.read ? (
                                <Button
                                    type="primary"
                                    ghost
                                    loading={isReadingNotification}
                                    onClick={handleReadNotification}
                                    size="small"
                                >
                                    Прочитать
                                </Button>
                            ) : (
                                <Text type="secondary">Прочитано</Text>
                            )}
                        </Row>
                    </Space>
                </Col>
            </Row>
        </List.Item>
    );
};

export default NotificationListItem;
