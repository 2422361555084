import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Icon from '@ant-design/icons';

import { Space, Typography, Row, Card, Button, Upload, Form, Alert, Grid, QuestionTooltip } from '@Shared/ui';
import { useGetProductsListForPromotionQuery } from '@Shared/api/rtkQueryApis/sellerPromotions';
import {
    FilterEnum,
    FilterObjectType,
    defaultFilterObject,
} from '@Features/seller/promotions/editPromotion/productsTable/types';
import { useUpdateSearchParams } from '@Shared/hooks';
import { DocumentFileUpload } from '@Shared/assets/images/icons';

import PromotionProductsTable from './productsTable/PromotionProductsTable';

import type { UploadProps } from 'antd';

import './styles.scss';

const { Text, Title, Link } = Typography;
const { useBreakpoint } = Grid;

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
});

const PromotionsTableBlock = ({
    promotionId = '',
    setImportedFileName,
    isAddPersonalPromotion,
}: {
    promotionId: string;
    setImportedFileName: (agr1: string) => void;
    isAddPersonalPromotion: boolean;
}) => {
    const [searchParams] = useSearchParams();
    const breakpoints = useBreakpoint();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const [importError, setImportError] = useState(false);
    const { data: products, isFetching } = useGetProductsListForPromotionQuery(
        {
            id: promotionId,
            page: filterObject.page,
            limit: filterObject.limit,
        },
        { skip: !promotionId },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    const uploadFileRequest = async (options) => {
        const { onSuccess, onError, file } = options;

        const fmData = new FormData();
        fmData.append('file', file);
        try {
            const res = await fetch(`${process.env.SERVER_URL}/api/private/file/upload/`, {
                credentials: 'include',
                method: 'POST',
                body: fmData,
            });
            const data = await res.json();
            onSuccess(data?.fileName);
        } catch (err) {
            onError({ err });
        }
    };

    const props: UploadProps = {
        name: 'file',
        customRequest: uploadFileRequest,
        maxCount: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        onChange(info) {
            if (info.file.status === 'done') {
                setImportedFileName(info.file.response);
            } else if (info.file.status === 'error') {
                setImportError(true);
            }
        },
    };

    return (
        <Card>
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <Row style={{ flexDirection: 'column' }}>
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Добавить товары &nbsp;
                        <QuestionTooltip
                            iconSize="18px"
                            title="Скачайте файл с товарами из акции в своем личном 
                        кабинете на Ozon и импортируйте его здесь"
                        />
                    </Title>
                    <Text type="secondary">
                        Импортируйте товары, участвующие в акции на Ozon, и настройте их видимость для своих паблишеров.
                    </Text>
                </Row>
                <Form.Item
                    name="promotion_products_file_id"
                    style={{ marginBottom: 0 }}
                >
                    <Upload
                        {...props}
                        className={breakpoints.md ? '' : 'personal-promotions-import-upload'}
                    >
                        <Button
                            style={{ width: breakpoints.md ? 'auto' : '100%' }}
                            icon={<Icon component={() => <DocumentFileUpload />} />}
                        >
                            Импортировать
                        </Button>
                    </Upload>
                </Form.Item>
                {importError ? (
                    <Row>
                        <Alert
                            description={
                                <Text>
                                    При загрузке товаров произошла ошибка. Пожалуйста, попробуйте снова. Если так и не
                                    получится загрузить товары, обратитесь в{' '}
                                    <Link href={`mailto:${process.env.SUPPORT_EMAIL}`}> поддержку</Link>.
                                </Text>
                            }
                            type="warning"
                            showIcon
                            banner
                            style={{ width: '100%' }}
                        />
                    </Row>
                ) : null}
                {!isAddPersonalPromotion ? (
                    <PromotionProductsTable
                        data={products}
                        isLoading={isFetching}
                        setFilterObject={setFilterObject}
                    />
                ) : null}
            </Space>
        </Card>
    );
};
export default PromotionsTableBlock;
