import { Row, Grid, Col } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/promotions/promotionsTab/model/types';
import { MarketplaceFilter, CheckboxFilter, ProductCategoriesFilter } from '@Features/shared/filters';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

import ExportButton from './ExportButton';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    selectedSourceId: string;
};

const PromotionsFilters = (props: PromotionsFilterProps) => {
    const { data: user } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();

    const YmChangeMarketplaceEvent = (value: string | string[]) => {
        if (Array.isArray(value)) {
            value.forEach((marketplace) => {
                sendYmEvent({
                    name: 'click_promotion_filter',
                    o1: 'account;publisher;promotions and promocodes',
                    o2: 'filter;change marketplace',
                    o4: marketplace,
                    publisherId: user?.publisher?.id,
                    keycloakId: user?.mitgo,
                });
            });
        }
    };

    const YmPromotionTypeChangeEvent = (value: string[]) => {
        if (Array.isArray(value)) {
            value.forEach((type) => {
                sendYmEvent({
                    name: 'click_promotion_filter',
                    o1: 'account;publisher;promotions and promocodes',
                    o2: 'filter;change promo type',
                    o4: type || '',
                    publisherId: user?.publisher?.id,
                    keycloakId: user?.mitgo,
                });
            });
        }
    };

    const YmChangeCheckboxEvent = (value: boolean) => {
        sendYmEvent({
            name: 'click_promotion_filter',
            o1: 'account;publisher;promotions and promocodes',
            o2: 'filter;change only available',
            o4: value ? 'enable' : 'disable',
            advertiserId: user?.publisher?.id,
            keycloakId: user?.mitgo,
        });
    };

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Col id="joyride-product-filters">
                <Row
                    wrap
                    align="middle"
                    style={{
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    <MarketplaceFilter<FilterObjectType>
                        {...props}
                        selectProps={{ mode: 'multiple' }}
                        propName="marketplaces"
                        onChange={YmChangeMarketplaceEvent}
                    />
                    <ProductCategoriesFilter<FilterObjectType>
                        {...props}
                        onChange={YmPromotionTypeChangeEvent}
                    />
                    {breakpoints.md ? (
                        <CheckboxFilter<FilterObjectType>
                            {...props}
                            propName="favorite"
                            label="Только избранные"
                            onChange={YmChangeCheckboxEvent}
                        />
                    ) : null}
                </Row>
            </Col>
            <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    {breakpoints.md ? null : (
                        <CheckboxFilter<FilterObjectType>
                            {...props}
                            propName="favorite"
                            label="Только избранные"
                            onChange={YmChangeCheckboxEvent}
                        />
                    )}
                    <ExportButton
                        sourceId={props.selectedSourceId}
                        filterObject={props.filterObject}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default PromotionsFilters;
