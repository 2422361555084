import { Row, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
import { SellerStoreFilter, MarketplaceFilter, CheckboxFilter } from '@Features/shared/filters';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const PromotionsFilters = (props: PromotionsFilterProps) => {
    const { data: user } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();

    const YmChangeStoreEvent = (value: string | string[]) => {
        if (value && typeof value === 'string') {
            sendYmEvent({
                name: 'click_promotion_filter',
                o1: 'account;advertiser;promotions and promocodes',
                o2: 'filter;change store',
                o4: value,
                advertiserId: user?.seller?.id,
                keycloakId: user?.mitgo,
                accountType: 'business',
            });
        }
    };

    const YmChangeMarketplaceEvent = (value: string | string[]) => {
        if (value && typeof value === 'string') {
            sendYmEvent({
                name: 'click_promotion_filter',
                o1: 'account;advertiser;promotions and promocodes',
                o2: 'filter;change marketplace',
                o4: value,
                advertiserId: user?.seller?.id,
                keycloakId: user?.mitgo,
                accountType: 'business',
            });
        }
    };

    const YmChangeCheckboxEvent = (value: boolean) => {
        sendYmEvent({
            name: 'click_promotion_filter',
            o1: 'account;advertiser;promotions and promocodes',
            o2: 'filter;change only available',
            o4: value ? 'enable' : 'disable',
            advertiserId: user?.seller?.id,
            keycloakId: user?.mitgo,
            accountType: 'business',
        });
    };

    return (
        <Row
            wrap
            align="middle"
            style={{
                gap: 12,
                flexDirection: breakpoints.md ? 'row' : 'column',
                alignItems: breakpoints.md ? 'center' : 'flex-start',
            }}
        >
            <SellerStoreFilter<FilterObjectType>
                {...props}
                onChange={YmChangeStoreEvent}
            />
            <MarketplaceFilter<FilterObjectType>
                {...props}
                onChange={YmChangeMarketplaceEvent}
            />
            <CheckboxFilter<FilterObjectType>
                {...props}
                label="Только доступные"
                onChange={YmChangeCheckboxEvent}
            />
        </Row>
    );
};

export default PromotionsFilters;
