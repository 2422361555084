import { useEffect } from 'react';
import { Spin, notification, message } from 'antd';

import { hideNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { MessageType } from '@Entities/messages/model/messagesSlice';
import { selectNotifications, selectMessages } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { EventPopupModal } from '@Features/modals';
import { MessageKeysType } from '@Shared/model/messageKeysType';

import SellerApp from './SellerApp';
import PublisherApp from './PublisherApp';
import { messageComponents } from './messageComponents';

import { useAuthSupportWidget } from '../hooks';

const TopLevelWrapper = () => {
    const dispatch = useAppDispatch();
    const { data: user, isLoading, isSuccess, error } = useGetTheUserInformationQuery();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [api, contextHolder] = notification.useNotification({
        placement: 'topRight',
        pauseOnHover: true,
    });
    const notifications = useAppSelector(selectNotifications);
    const messages = useAppSelector(selectMessages);
    useAuthSupportWidget();

    useEffect(() => {
        if (notifications?.componentProps?.message) {
            api[notifications?.componentProps?.type as NotificationType]({
                message: notifications?.componentProps?.message,
                description: notifications?.componentProps?.description,
                btn: notifications?.componentProps?.btn,
                onClose: () => dispatch(hideNotification()),
                duration: notifications?.componentProps?.duration || 5,
            });
        }
    }, [api, dispatch, notifications]);

    useEffect(() => {
        if (messages.componentProps.content) {
            let MessageComponent = null;
            if (String(messages.componentProps.content) in messageComponents) {
                MessageComponent = messageComponents[messages.componentProps.content as MessageKeysType];
            }
            messageApi.open({
                type: messages?.componentProps?.type || MessageType.Info,
                content: MessageComponent ? (
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    <MessageComponent {...(messages.messageProps as any)} />
                ) : (
                    messages?.componentProps?.content || ''
                ),
                duration: messages?.componentProps?.duration,
            });
        }
    }, [messageApi, dispatch, messages]);

    if (isLoading) {
        return (
            <Spin
                fullscreen
                size="large"
            />
        );
    }

    if (error) {
        return null;
    }

    if (isSuccess) {
        const hasSellerRole = (user?.roles || []).indexOf('ROLE_SELLER') !== -1;
        const hasPublisherRole = (user?.roles || []).indexOf('ROLE_PUBLISHER') !== -1;
        const isRoleSelected = hasSellerRole || hasPublisherRole;

        if (!isRoleSelected && window.location.pathname !== '/welcome') {
            window.location.href = '/welcome';
            return null;
        }

        const currentApp = hasSellerRole ? <SellerApp /> : <PublisherApp />;

        return (
            <>
                {currentApp}
                {contextHolder}
                {messageContextHolder}
                <EventPopupModal />
            </>
        );
    }
};

export default TopLevelWrapper;
