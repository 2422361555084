import { theme } from 'antd';

import { Badge, Typography } from '@Shared/ui';
import { getCpcCampaignStatusBadgeType } from '@Shared/utils/finances/getCpcCommissionStatusBadgeType';
import { CommissionStatusEnum } from '@Shared/api/rtkQueryApis/sellerCommissions';
import { CpcCommissionStatusesMap } from '@Features/seller/finances/cpcCommissions/model/types';

const { Text } = Typography;

const StatusCell = ({ status }: { status: CommissionStatusEnum }) => {
    const {
        token: { colorTextTertiary, colorText },
    } = theme.useToken();

    return (
        <Badge
            status={getCpcCampaignStatusBadgeType(status)}
            text={
                <Text style={{ color: status === CommissionStatusEnum.Declined ? colorTextTertiary : colorText }}>
                    {CpcCommissionStatusesMap[status]}
                </Text>
            }
        />
    );
};

export default StatusCell;
