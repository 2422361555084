import { Row, Grid, Col } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/promotions/sellersPromotionsTab/model/types';
import { MarketplaceFilter, CheckboxFilter } from '@Features/shared/filters';

import ExportButton from './ExportButton';
import PromotionTypesFilter from './PromotionTypesFilter';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    selectedSourceId: string;
};

const SellersPromotionsFilters = (props: PromotionsFilterProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Col id="joyride-product-filters">
                <Row
                    wrap
                    align="middle"
                    style={{
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    <MarketplaceFilter<FilterObjectType>
                        {...props}
                        selectProps={{ mode: 'multiple' }}
                        propName="marketplaces"
                    />
                    <PromotionTypesFilter {...props} />
                    {breakpoints.md ? (
                        <CheckboxFilter<FilterObjectType>
                            {...props}
                            propName="favorite"
                            label="Только избранные"
                        />
                    ) : null}
                </Row>
            </Col>
            <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    {breakpoints.md ? null : (
                        <CheckboxFilter<FilterObjectType>
                            {...props}
                            propName="favorite"
                            label="Только избранные"
                        />
                    )}
                    <ExportButton
                        sourceId={props.selectedSourceId}
                        filterObject={props.filterObject}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default SellersPromotionsFilters;
