import { Dispatch, SetStateAction, useMemo } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, QuestionTooltip, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/promotions/sellersPromotionsTab/model/types';
import {
    GetGlobalPromotionsProductsListWithLinksBySourceApiResponse,
    PromotionDto2,
    PromotionTypeEnum4,
} from '@Shared/api/rtkQueryApis/publisherPromotions';
import { MPTitleCell, CopyTextLinkCell } from '@Features/shared/TableCells';
import { formatPrice } from '@Shared/utils/formatPrice';
import { FAKE_LINK } from '@Shared/constants';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

import { PeriodCell, CouponCell, FavoriteCell, ExportPromotionProductsCell } from './cells';

const { useBreakpoint } = Grid;

import './styles.scss';

type PromotionsTableProps = {
    data: GetGlobalPromotionsProductsListWithLinksBySourceApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    setPromotionId: Dispatch<SetStateAction<string>>;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    selectedSourceId: string;
};

const { Text } = Typography;

const PromotionsTable = ({
    data,
    isLoading,
    setFilterObject,
    setPromotionId,
    setDrawerOpen,
    selectedSourceId,
}: PromotionsTableProps) => {
    const { data: user } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();

    const columns = useMemo(
        () => [
            {
                width: 48,
                render: (_: unknown, promotion: PromotionDto2) => (
                    <FavoriteCell
                        isFavorite={!!promotion.favorite}
                        promotionId={promotion?.id || ''}
                    />
                ),
            },
            {
                title: 'Название акции',
                dataIndex: 'title',
                key: 'title',
                ellipsis: true,
                width: 260,
            },
            {
                title: 'Магазин',
                dataIndex: 'marketplace_store',
                key: 'marketplace_store',
                render: (_: unknown, promotion: PromotionDto2) => (
                    <MPTitleCell marketplaceStore={promotion.marketplace_store} />
                ),
            },
            {
                title: 'Скидка',
                dataIndex: 'discount_value',
                key: 'discount_value',
                render: (discount_value: string, promotion: PromotionDto2) => {
                    const formatedDiscountPercent = formatPrice({
                        price: discount_value,
                        feeType: promotion.discount_type,
                    });
                    if (promotion.type === PromotionTypeEnum4.GlobalSale) {
                        if (Number(discount_value)) {
                            return `до ${formatedDiscountPercent}`;
                        }
                        return '';
                    }

                    return formatedDiscountPercent;
                },
            },
            {
                title: 'Период акции',
                dataIndex: 'start_date',
                key: 'start_date',
                render: (_: unknown, promotion: PromotionDto2) => <PeriodCell promotion={promotion} />,
            },
            {
                title: (
                    <>
                        Товары акции &nbsp;
                        <QuestionTooltip title="Вы можете скачать полный список товаров и своих комиссий по ним в CSV" />
                    </>
                ),
                key: 'export_products',
                render: (_: unknown, promotion: PromotionDto2) => (
                    <ExportPromotionProductsCell
                        sourceId={selectedSourceId}
                        promotionId={promotion?.id || ''}
                    />
                ),
            },
            {
                title: 'Промокод',
                dataIndex: 'coupon',
                key: 'coupon',
                fixed: 'right',
                width: breakpoints.md ? 'auto' : 80,
                render: (coupon: string | null) => <CouponCell coupon={coupon} />,
            },
            {
                title: (
                    <>
                        Витрина акции &nbsp;
                        <QuestionTooltip title="Акционный лендинг с товарами, ссылку на который вам необходимо разместить" />
                    </>
                ),
                dataIndex: 'landing_link',
                key: 'landing_link',
                fixed: 'right',
                width: breakpoints.md ? 'auto' : 130,
                render: (landing_link: string) => (
                    <CopyTextLinkCell
                        type="link"
                        copyValue={landing_link || FAKE_LINK}
                        copyMessageText="Ссылка на сайт акции успешно скопирована"
                        href={landing_link}
                        blurred={!landing_link}
                        ymEvent={() => {
                            sendYmEvent({
                                name: 'click_copy_promotion_showcase_link',
                                o1: 'account;publisher;promotions and promocodes',
                                o2: 'from seller',
                                publisherId: user?.publisher?.id,
                                keycloakId: user?.mitgo,
                                sendboxId: landing_link || '',
                            });
                        }}
                    />
                ),
            },
        ],
        [breakpoints.md, selectedSourceId, user],
    );

    return (
        <Table
            scroll={{ x: '1300px' }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            rowClassName={(record) => (record.favorite ? 'sellers-promotion-products-table__favorite' : '')}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setPromotionId(record?.id || '');
                        setDrawerOpen(true);
                        sendYmEvent({
                            name: 'click_item',
                            o1: 'account;publisher;promotions and promocodes',
                            o2: 'from seller',
                            publisherId: user?.publisher?.id,
                            keycloakId: user?.mitgo,
                            sendboxId: String(record.id) || '',
                        });
                    },
                };
            }}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: (
                        <Text>
                            {selectedSourceId ? 'Нет доступных скидок' : 'Чтобы продолжить работу выберите прощадку'}
                        </Text>
                    ),
                }),
            }}
        />
    );
};

export default PromotionsTable;
