import { useNavigate } from 'react-router-dom';
import Icon, { PlusCircleOutlined } from '@ant-design/icons';

import { Button, Typography, Row, Grid, Card, Col, Space, Avatar, Skeleton } from '@Shared/ui';
import { getMarketPlaceIconBySlug } from '@Shared/utils/stores/getMarkeplaceIconBySlug';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { useGetAListOfAvailableMarketplacesQuery, MarketplaceDto } from '@Shared/api/rtkQueryApis/sellerIntegrations';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores';

import './styles.scss';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

const MarketplaceCard = ({
    marketplace,
    isSetupButtonDisabled,
}: {
    marketplace: MarketplaceDto;
    isSetupButtonDisabled: boolean;
}) => {
    const navigate = useNavigate();

    const { data: user } = useGetTheUserInformationQuery();

    const MPlogo = marketplace?.slug ? getMarketPlaceIconBySlug(marketplace.slug) : null;

    const handleClickSetIntegration = () => {
        sendYmEvent({
            name: 'click_set_integration',
            o1: 'account;advertiser;store',
            o2: 'marketplaces',
            o4: marketplace.slug,
            advcampaignId: user?.seller?.id,
            keycloakId: user?.mitgo,
        });

        navigate('/stores/setup-integration', {
            state: { selectedMarketplace: marketplace.slug },
        });
    };

    return (
        <Card
            bordered={false}
            className="marketplaces-tab__marketplace-card"
        >
            <Space
                direction="vertical"
                align="start"
                size="middle"
                className="marketplaces-tab__marketplace-card__content"
            >
                <Space
                    direction="vertical"
                    align="start"
                    size="middle"
                >
                    <Avatar
                        shape="square"
                        size={40}
                        icon={
                            <Icon component={() => (MPlogo ? <MPlogo style={{ width: 40, height: 40 }} /> : <></>)} />
                        }
                    />
                    <Row>
                        <Title
                            level={5}
                            style={{ marginTop: 0 }}
                        >
                            {marketplace.title}
                        </Title>

                        <Text type="secondary">{marketplace.description}</Text>
                    </Row>
                </Space>
                <Button
                    type="primary"
                    onClick={() => handleClickSetIntegration()}
                    disabled={isSetupButtonDisabled}
                >
                    Настроить
                </Button>
            </Space>
        </Card>
    );
};

const MarketplacesTab = () => {
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();
    const { data: marketplaces, isLoading } = useGetAListOfAvailableMarketplacesQuery();
    const { data: stores } = useGetStoresListQuery();

    const { data: user } = useGetTheUserInformationQuery();

    const isSetupButtonDisabled = !stores?.items?.length;

    if (isLoading) return <Skeleton active />;

    const handleClickNewIntegration = () => {
        sendYmEvent({
            name: 'click_set_integration',
            o1: 'account;advertiser;store',
            o2: 'marketplaces',
            advcampaignId: user?.seller?.id,
            keycloakId: user?.mitgo,
        });
        navigate('/stores/setup-integration');
    };

    return (
        <>
            <Row
                justify="space-between"
                align="top"
                style={{ columnGap: 16, rowGap: 24, flexDirection: breakpoints.lg ? 'row' : 'column-reverse' }}
                wrap={false}
            >
                <Text type="secondary">В этом разделе хранится список маркетплейсов.</Text>
                <Button
                    icon={<PlusCircleOutlined />}
                    iconPosition="start"
                    type="primary"
                    ghost
                    onClick={handleClickNewIntegration}
                    disabled={isSetupButtonDisabled}
                >
                    Новая интеграция
                </Button>
            </Row>
            <Row gutter={[24, 24]}>
                {(marketplaces?.items || []).map((marketplace) => (
                    <Col
                        key={marketplace.slug}
                        style={{ width: 240, flex: breakpoints.md ? 'unset' : '60% 1 1' }}
                    >
                        <MarketplaceCard
                            marketplace={marketplace}
                            isSetupButtonDisabled={isSetupButtonDisabled}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
export default MarketplacesTab;
