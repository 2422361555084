import { fileTypeTitles } from './constants';

export const getFileTypesTitles = (accept: string) => {
    if (!accept) {
        return '';
    }

    return accept
        .replaceAll(' ', '')
        .split(',')
        .map((fileType) => fileTypeTitles?.[fileType] ?? fileType)
        .join(', ');
};

export const getUploadFilesAccept = (fileTypes: string | string[]) => {
    if (typeof fileTypes === 'string') {
        return fileTypes;
    }

    return fileTypes.join(', ');
};
