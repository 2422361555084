import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Form, Row, Button, Space, Grid, Skeleton } from '@Shared/ui';
import {
    useViewTheCampaignQuery,
    BarterCampaignDto,
    BarterCampaignDto2,
    IntegrationNumberEnum,
    usePutAppPrivateSellerCampaignUpdatebarterMutation,
    useAddABarterCampaignMutation,
    DeadlineEnum,
    PaymentTypeEnum3,
    MarketPlaceSlugEnum2,
    CampaignStatusEnum,
} from '@Shared/api/rtkQueryApis/sellerCampaigns';
import { SelectedMPStore } from '@Features/seller/productPromotion/campaignsTab/setupCampaign/barter/model/types';
import { useGetStoresListQuery } from '@Shared/api/rtkQueryApis/sellerStores/SellerStoresApi';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

import SelectMPstoreCard from './SelectMPstoreCard';
import CampaignMainDetailsCard from './CampaignMainDetailsCard';
import AdFormatCard from './AdFormatCard';
import PaymentTypeCard from './PaymentTypeCard';
import DeadlineCard from './DeadlineCard';
import ProductCampaignsCard from './ProductCampaignsCard';

const { useBreakpoint } = Grid;

const initialCampaignProducts = [
    {
        product: null,
        advertising_guidance: '',
        need_review: false,
        keywords: '',
    },
];

const BarterForm = () => {
    const [selectedMPStore, setMPstore] = useState<SelectedMPStore | null>(null);
    const breakpoints = useBreakpoint();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const selectedIntegrationNumber = Form.useWatch(['barter_setting', 'integration_number'], form);
    const { slug = '' } = useParams();
    const { data: campaign, isLoading: isCampaignLoading } = useViewTheCampaignQuery({ slug }, { skip: !slug });
    const { data: stores } = useGetStoresListQuery();
    const { data: user } = useGetTheUserInformationQuery();
    const [addBarterCampaign, { isLoading: isAddingCampaign, error: isAddingError }] = useAddABarterCampaignMutation();
    const [updateBarterCampaign, { isLoading: isUpdatingCampaign, error: isUpdatingError }] =
        usePutAppPrivateSellerCampaignUpdatebarterMutation();

    const isCampaignActive = campaign?.status === CampaignStatusEnum.Active;
    const isFormDisabled = isCampaignActive || !selectedMPStore;

    useEffect(() => {
        if (campaign) {
            const formData: BarterCampaignDto = {
                title: campaign.title,
                marketplace_store: campaign.marketplace_store?.id || '',
                barter_setting: campaign.barter_setting,
                product_campaigns: campaign?.product_campaigns?.map((productCampaigns) => ({
                    ...productCampaigns,
                    product: productCampaigns.product?.id,
                })),
            };
            form.setFieldsValue(formData);
        }
    }, [campaign, form]);

    useEffect(() => {
        const errObject = isAddingError || isUpdatingError;
        if (errObject) {
            const errors = normalizeErrorsObject(errObject?.data?.errors);
            const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
            form.setFields(formErrors);
        }
    }, [form, isAddingError, isUpdatingError]);

    useEffect(() => {
        if (stores?.items?.length) {
            if (campaign) {
                const currentStore = stores?.items?.find((store) =>
                    store?.marketplaceStores?.some(
                        (marketplaceStore) => marketplaceStore.id === campaign.marketplace_store?.id,
                    ),
                );
                const currentMPstore = (currentStore?.marketplaceStores || []).find(
                    (mpStore) => mpStore.id === campaign.marketplace_store?.id,
                );
                setMPstore({
                    marketplaceSlug: currentMPstore?.marketplace_slug || '',
                    storeId: currentStore?.id || '',
                    mpStoreId: currentMPstore?.id || '',
                });
            } else {
                const marketplaceStores =
                    stores?.items.find(
                        (item) => Array.isArray(item.marketplaceStores) && item.marketplaceStores.length > 0,
                    )?.marketplaceStores || [];
                if (marketplaceStores?.length) {
                    form.setFieldValue('marketplace_store', marketplaceStores[0].id);
                    form.setFieldValue(
                        ['barter_setting', 'payment_type'],
                        marketplaceStores?.[0].marketplace_slug === MarketPlaceSlugEnum2.Ozon
                            ? PaymentTypeEnum3.PromoCode
                            : PaymentTypeEnum3.Certificate,
                    );
                    setMPstore({
                        marketplaceSlug: marketplaceStores?.[0]?.marketplace_slug || '',
                        storeId: stores?.items[0].id,
                        mpStoreId: marketplaceStores?.[0].id || '',
                    });
                }
            }
        }
    }, [campaign, form, stores?.items]);

    const handleBack = () => {
        navigate(-1);
    };

    const initialValues = {
        barter_setting: {
            integration_number: IntegrationNumberEnum.$30 || 30,
            publisher_requirements: null,
            deadline: DeadlineEnum.Month1,
            payment_type: PaymentTypeEnum3.Certificate,
        },
        product_campaigns: initialCampaignProducts,
    };

    const onFinish = async (formValues: BarterCampaignDto2) => {
        try {
            if (slug) {
                const res = await updateBarterCampaign({
                    barterCampaignDto2: formValues,
                    notificationOptions: {
                        successMessage: 'Рекламная кампания успешно обновлена',
                    },
                    slug,
                });
                if (!res.error) {
                    handleBack();
                }
            } else {
                const res = await addBarterCampaign({
                    barterCampaignDto: formValues,
                    notificationOptions: {
                        successMessage: 'Рекламная кампания успешно сохранена',
                    },
                });
                if (!res.error) {
                    sendYmEvent({
                        name: 'send_start_ads_success',
                        o1: 'account;advertiser;ads campaign',
                        o2: 'create ads;form',
                        o4: 'barter',
                        advertiserId: user?.seller?.id,
                        keycloakId: user?.mitgo,
                    });
                    handleBack();
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    if (isCampaignLoading) return <Skeleton active />;

    return (
        <Form
            form={form}
            name="setup-barter-campaign-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
            className="setup-barter-campaign__form"
            initialValues={initialValues}
            scrollToFirstError
        >
            <Space
                direction="vertical"
                size={24}
                style={{ width: '100%' }}
            >
                <SelectMPstoreCard
                    onChange={(val, option) => {
                        const mpSlug = option?.store?.marketplaceStores?.find(
                            (store) => store?.id === val,
                        )?.marketplace_slug;
                        form.setFieldValue(['marketplace_store'], val);
                        form.setFieldValue(['product_campaigns'], initialCampaignProducts);
                        form.setFieldValue(
                            ['barter_setting', 'payment_type'],
                            option?.store?.marketplaceStores?.[0].marketplace_slug === MarketPlaceSlugEnum2.Ozon
                                ? PaymentTypeEnum3.PromoCode
                                : PaymentTypeEnum3.Certificate,
                        );
                        setMPstore({ marketplaceSlug: mpSlug, storeId: option.store.id, mpStoreId: val });
                    }}
                    value={selectedMPStore?.mpStoreId || ''}
                    isFormDisabled={isFormDisabled}
                    isEditForm={!!slug}
                />
                <CampaignMainDetailsCard
                    selectedIntegrationNumber={selectedIntegrationNumber}
                    isFormDisabled={isFormDisabled}
                />
                <AdFormatCard
                    onPublisherRequirementsChange={(val) =>
                        form.setFieldValue(['barter_setting', 'publisher_requirements'], val)
                    }
                    publisherRequirementsValue={form.getFieldValue(['barter_setting', 'publisher_requirements'])}
                    isFormDisabled={isFormDisabled}
                />
                <Row
                    style={{ gap: 20 }}
                    wrap={!breakpoints.md}
                >
                    <PaymentTypeCard isFormDisabled={isFormDisabled} />
                    <DeadlineCard isFormDisabled={isFormDisabled} />
                </Row>
                <ProductCampaignsCard
                    selectedMPStore={selectedMPStore}
                    form={form}
                    isFormDisabled={isFormDisabled}
                />
                <Form.Item>
                    <Row
                        className="action-buttons-block"
                        style={{
                            justifyContent: 'space-between',
                            gap: 10,
                            flexDirection: breakpoints.sm ? 'row' : 'column-reverse',
                        }}
                    >
                        <Button
                            onClick={handleBack}
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                        >
                            Отменить
                        </Button>
                        {!isCampaignActive ? (
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={isFormDisabled}
                                loading={isAddingCampaign || isUpdatingCampaign}
                                style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                            >
                                {slug ? 'Обновить кампанию' : 'Запустить кампанию'}
                            </Button>
                        ) : null}
                    </Row>
                </Form.Item>
            </Space>
        </Form>
    );
};
export default BarterForm;
