import { Button } from '@Shared/ui';
import { MailboxDownload } from '@Shared/assets/images/icons';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user/UserApi';
import { useExport } from '@Shared/hooks';
import { queryParamsSerializer } from '@Shared/utils/queryParamsSerializer';

import { FilterObjectType } from '../model/types';

const BASE_URL = process.env.SERVER_URL;

const ExportButton = ({ sourceId, filterObject }: { sourceId: string; filterObject: FilterObjectType }) => {
    const { data: user } = useGetTheUserInformationQuery();
    const { handleDownload, isLoading } = useExport();

    const handleClick = () => {
        const params = queryParamsSerializer(filterObject);
        handleDownload(
            `${BASE_URL}/api/publisher/v1/source/${sourceId}/promotion/export/?${params}`,
            'promotion_products_export.csv',
        );
    };

    return (
        <Button
            onClick={handleClick}
            loading={isLoading}
            disabled={!user?.publisher?.legalInfo}
            icon={<MailboxDownload />}
        />
    );
};

export default ExportButton;
