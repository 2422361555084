import { type SelectProps } from 'antd';

import { Grid, Select } from '@Shared/ui';
import { MarketPlaceSlugEnum } from '@Shared/api/full';

const { useBreakpoint } = Grid;

type MarketplaceFilterProps<FilterObjectType> = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    propName?: keyof FilterObjectType;
    selectProps?: SelectProps;
    onChange?: (value: string[] | string) => void;
};

const MarketplaceFilter = <FilterObjectType,>({
    filterObject,
    setFilterObject,
    propName = 'marketplaceSlug' as keyof FilterObjectType,
    selectProps = {},
    onChange,
}: MarketplaceFilterProps<FilterObjectType>) => {
    const breakpoints = useBreakpoint();
    const marketplacesOptions = Object.keys(MarketPlaceSlugEnum).map((marketplace) => ({
        label: marketplace,
        value: MarketPlaceSlugEnum[marketplace],
    }));

    const handleOnchange = (value: string[] | string) => {
        setFilterObject({ ...filterObject, [propName]: value, page: 1 });
        onChange?.(value);
    };

    return (
        <Select
            placeholder="Выберите маркетплейс"
            onChange={handleOnchange}
            value={filterObject[propName] || ''}
            style={{ width: breakpoints.md ? 240 : '100%' }}
            options={[
                ...(selectProps.mode === 'multiple' ? [] : [{ value: '', label: 'Все маркетплейсы' }]),
                ...marketplacesOptions,
            ]}
            {...selectProps}
        />
    );
};

export default MarketplaceFilter;
