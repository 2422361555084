import { Col, Row, Upload, theme } from 'antd';
import Icon, { InboxOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button, Typography, Spin } from '@Shared/ui';
import { formatFileSize } from '@Shared/utils/formatFileSize';
import { TrashDeleteBinLarge, UploadItem } from '@Shared/assets/images/icons';

import { DraggerErrorType, DraggerProps } from './types';
import { getFileTypesTitles } from './utils';

import type { UploadFile, UploadProps } from 'antd';

const { Dragger: _Dragger } = Upload;
const { Text } = Typography;

const Dragger = ({ text, hint, maxFileSize, onError, error, onRemove, ...props }: DraggerProps) => {
    const [uploadList, setUploadList] = useState<UploadFile[]>([]);
    const [uploadError, setUploadError] = useState<DraggerProps['error']>(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleError = (error: DraggerProps['error']) => {
        if (onError && typeof error !== 'undefined') {
            onError(error);
        }
        setUploadError(error);
    };

    const {
        token: { colorTextDescription, colorError, colorPrimary },
    } = theme.useToken();
    const uploadProps: UploadProps = {
        ...props,
        showUploadList: false,
        fileList: uploadList,
        beforeUpload: (file, fileList) => {
            let beforeUploadError = null;
            if (props?.beforeUpload) {
                props?.beforeUpload(file, fileList);
            }
            handleError(null);

            // TODO: there are a lot of differents between blob file types and accept types, research and change
            if (props?.accept && !props?.accept?.includes(file.type)) {
                beforeUploadError = {
                    type: DraggerErrorType.error,
                    message: 'Неверный формат файла.',
                };
            }

            if (typeof maxFileSize === 'undefined' && !beforeUploadError) {
                return true;
            }

            if (maxFileSize && file.size > maxFileSize) {
                beforeUploadError = {
                    type: DraggerErrorType.error,
                    message: `Файл превышает ${formatFileSize(maxFileSize)}.`,
                };
            }

            handleError(beforeUploadError);
            if (beforeUploadError) {
                return false;
            }
            return true;
        },
        onChange: (info) => {
            const { status } = info.file;
            setUploadList(info?.fileList || []);
            if (status === 'uploading') {
                setIsUploading(true);
            } else {
                setIsUploading(false);
            }
            if (status === 'error') {
                handleError({
                    type: DraggerErrorType.error,
                    message: 'Ошибка загрузки файла.',
                });

                return;
            }

            if (props?.onChange) {
                props?.onChange(info);
            }
        },
        disabled: isUploading,
    };

    const handleRemoveFile = (fileUid: string) => {
        setUploadList(uploadList.filter((file) => file.uid !== fileUid));
        handleError(null);
        onRemove();
    };
    return (
        <>
            <_Dragger {...uploadProps}>
                {isUploading ? (
                    <div
                        style={{
                            minHeight: '48px',
                            marginTop: 14,
                            marginBottom: 16,
                        }}
                    >
                        <Spin size="large" />
                    </div>
                ) : (
                    <p
                        className="ant-upload-drag-icon"
                        style={{
                            minHeight: '48px',
                        }}
                    >
                        <InboxOutlined />
                    </p>
                )}

                {text && <p className="ant-upload-text">{text}</p>}
                {hint && <p className="ant-upload-hint">{hint}</p>}
            </_Dragger>
            {props?.accept && (
                <Text
                    style={{
                        color: colorTextDescription,
                        display: 'block',
                    }}
                >
                    Поддерживаемые форматы:
                    <span
                        style={{
                            textTransform: 'uppercase',
                        }}
                    >
                        {' '}
                        {getFileTypesTitles(props.accept)}
                    </span>
                </Text>
            )}
            {maxFileSize && (
                <Text
                    style={{
                        color: colorTextDescription,
                    }}
                >
                    Максимальный размер файла: {formatFileSize(maxFileSize)}
                </Text>
            )}

            {Boolean(uploadList?.length) && (
                <Row
                    style={{
                        marginTop: 20,
                    }}
                >
                    {uploadList.map((file) => {
                        const isError = file?.error || error || uploadError;
                        return (
                            <Row
                                key={file.uid}
                                style={{
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Col
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        maxWidth: '80%',
                                    }}
                                >
                                    <Icon
                                        component={() => <UploadItem />}
                                        style={{
                                            paddingRight: 8,
                                            color: isError ? colorError : colorPrimary,
                                        }}
                                    />
                                    <Text
                                        style={{
                                            color: isError ? colorError : colorPrimary,
                                        }}
                                        ellipsis
                                    >
                                        {file.name}
                                    </Text>
                                </Col>
                                <Col>
                                    <Button
                                        type="link"
                                        danger
                                        disabled={isUploading}
                                        style={{
                                            height: '22px',
                                            width: '22px',
                                        }}
                                        icon={
                                            <Icon
                                                component={() => (
                                                    <TrashDeleteBinLarge
                                                        width={22}
                                                        height={22}
                                                    />
                                                )}
                                            />
                                        }
                                        onClick={() => handleRemoveFile(file.uid)}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                </Row>
            )}
            {(uploadError || error) && (
                <Text
                    style={{
                        color: colorError,
                        marginTop: 4,
                        display: 'block',
                    }}
                >
                    {error?.message || uploadError?.message}
                </Text>
            )}
        </>
    );
};

export default Dragger;
