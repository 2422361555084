export const queryParamsSerializer = (params) => {
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(params)) {
        const value = params[key];

        if (Array.isArray(value)) {
            if (value.length) {
                value.forEach((item) => searchParams.append(`${key}[]`, item));
            }

            continue;
        }

        if (typeof value === 'boolean') {
            searchParams.append(key, String(value ? 1 : 0));
            continue;
        }

        if (value) {
            searchParams.append(key, value);
        }
    }

    return searchParams.toString();
};
