import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button, Form, Input, Typography, Space, Card, Select, Skeleton, Grid, Row } from '@Shared/ui';
import { ConfirmLeavePrompt } from '@Features/shared';
import {
    useGetSourceContentTypesListQuery,
    SourceDto2,
    SourceDto3,
    SourceStatusEnum,
} from '@Shared/api/rtkQueryApis/publisherSources';
import { useGetSourceTypesListQuery, SourceTypeEnum } from '@Shared/api/rtkQueryApis/common/commonApi';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { useYup } from '@Shared/hooks';

// import AudienceFormItems from './AudienceFormItems';
import SourceVerificationBlock from './SourceVerificationBlock';
import './styles.scss';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

type SourceFormProps = {
    isSubmitting: boolean;
    errors: Partial<SourceDto2>;
    initialData: Partial<SourceDto3>;
    handleSubmit: (arg1: SourceDto3, sourceSlug?: string) => void;
    isAddSourceForm?: boolean;
    isFormDataLoading?: boolean;
    isSubmitted: boolean;
};

const SourceForm = ({
    isSubmitting,
    errors = {},
    initialData,
    handleSubmit,
    isAddSourceForm = false,
    isFormDataLoading = false,
    isSubmitted,
}: SourceFormProps) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const breakpoints = useBreakpoint();
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const [currentSourceType, setCurrentSourceType] = useState<SourceTypeEnum | undefined>();
    const { data: sourceTypes, isLoading: isSourceTypesLoading } = useGetSourceTypesListQuery();
    const { data: contentTypes, isLoading: isContentTypesLoading } = useGetSourceContentTypesListQuery();
    const { data: user, isLoading: isUserLoading } = useGetTheUserInformationQuery();
    const isArbitrageSource = currentSourceType === SourceTypeEnum.Arbitrage;
    const getSourceUrlValidation = () => {
        const urlValidation = yup.string().url('Это поле должно быть дейсвительным URL адрессом');
        if (!isArbitrageSource) {
            return urlValidation.required('Это поле обязательно к заполнению');
        }
        return urlValidation;
    };
    const yupRules = useYup({
        title: yup.string().required('Это поле обязательно к заполнению'),
        source_type: yup.string().required('Это поле обязательно к заполнению'),
        source_url: getSourceUrlValidation(),
        source_content_types: yup.array().of(yup.string()).required('Это поле обязательно к заполнению'),
    });

    const isFieldsDisabled =
        initialData?.status === SourceStatusEnum.Approved || initialData?.status === SourceStatusEnum.Verification;

    const onFinish = (formValues: SourceDto3) => {
        const sourceSlug = sourceTypes?.items?.find((type) => type.id === +formValues.source_type)?.slug;

        handleSubmit(formValues, sourceSlug);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {
        if (initialData?.source_type) {
            const sourceType = sourceTypes?.items?.find((type) => type.id === +initialData?.source_type)?.type;
            setCurrentSourceType(sourceType);
        }
    }, [initialData, sourceTypes?.items]);

    useEffect(() => {
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [errors, form]);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        if (isSubmitted) {
            setUnsavedChanges(false);
        }
    }, [handleBack, isSubmitted]);

    useEffect(() => {
        if (isSubmitted && !hasUnsavedChanges) {
            handleBack();
        }
    }, [handleBack, hasUnsavedChanges, isSubmitted]);

    const sourceTypeOptions = sourceTypes?.items?.map((source) => ({
        value: source.id + '',
        label: source.title,
    }));

    const sourceContentTypeOptions = contentTypes?.items?.map((type) => ({
        value: type.id,
        label: type.title,
    }));

    if (isFormDataLoading || isContentTypesLoading || isSourceTypesLoading) return <Skeleton active />;

    return (
        <Form
            form={form}
            name="source"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={(changedFields) => {
                const hasTouchedField = changedFields.some((field) => field.touched);
                if (hasTouchedField) {
                    setUnsavedChanges(true);
                }
            }}
            autoComplete="off"
            className="add-edit-source__form"
            initialValues={initialData}
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Space
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Card style={{ padding: '8px 0' }}>
                    <Title
                        level={3}
                        style={{ marginTop: 0 }}
                    >
                        {isAddSourceForm ? 'Добавление площадки' : 'Редактирование площадки'}
                    </Title>
                    <Text type="secondary">Укажите основную информацию о площадке.</Text>
                    <Form.Item
                        label="Название площадки"
                        name="title"
                        validateTrigger="onBlur"
                        rules={[{ required: true, message: '' }, ...yupRules]}
                        style={{ marginTop: 20 }}
                    >
                        <Input
                            placeholder="Укажите название площадки"
                            disabled={isFieldsDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Тип площадки"
                        name="source_type"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[{ required: true, message: '' }, ...yupRules]}
                        style={{ marginTop: 20 }}
                    >
                        <Select
                            style={{ width: '100%' }}
                            showSearch={false}
                            loading={isSourceTypesLoading}
                            options={sourceTypeOptions}
                            placeholder="Укажите тип площадки"
                            disabled={isFieldsDisabled}
                            onChange={(value) => {
                                const sourceType = sourceTypes?.items?.find((type) => type.id === +value)?.type;
                                setCurrentSourceType(sourceType);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Ссылка на площадку"
                        name="source_url"
                        validateTrigger="onBlur"
                        rules={[{ required: !isArbitrageSource, message: '' }, ...yupRules]}
                        style={{ marginTop: 20, marginBottom: 0 }}
                    >
                        <Input
                            placeholder="Укажите ссылку на площадку"
                            disabled={isFieldsDisabled}
                        />
                    </Form.Item>
                </Card>
                <Card style={{ padding: '8px 0' }}>
                    <Title
                        level={4}
                        style={{ marginTop: 0 }}
                    >
                        Контент
                    </Title>
                    <Form.Item
                        label="Тип контента"
                        name="source_content_types"
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[{ required: true, message: '' }, ...yupRules]}
                        style={{ marginBottom: 0 }}
                    >
                        <Select
                            style={{ width: '100%' }}
                            mode="multiple"
                            showSearch={false}
                            loading={isContentTypesLoading}
                            options={sourceContentTypeOptions}
                            placeholder="Укажите тип контента"
                            disabled={isFieldsDisabled}
                        />
                    </Form.Item>
                </Card>
                {currentSourceType && !isArbitrageSource ? (
                    <SourceVerificationBlock
                        verificationCode={user?.publisher?.publisherVerificationCode || ''}
                        isLoading={isUserLoading}
                        currentSourceType={currentSourceType}
                        isAddSourceForm={isAddSourceForm}
                    />
                ) : null}
                <Form.Item style={{ padding: breakpoints.md ? 0 : '0 24px' }}>
                    <Row
                        className="action-buttons-block"
                        style={{
                            justifyContent: 'space-between',
                            flexDirection: breakpoints.sm ? 'row' : 'column-reverse',
                        }}
                    >
                        <Button
                            onClick={() => handleBack()}
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                        >
                            Отменить
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isFieldsDisabled}
                            style={{ flex: breakpoints.sm ? 'unset' : 1 }}
                            loading={isSubmitting}
                        >
                            {isAddSourceForm ? 'Добавить площадку' : 'Сохранить изменения'}
                        </Button>
                    </Row>
                </Form.Item>
            </Space>
        </Form>
    );
};
export default SourceForm;
