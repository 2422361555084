// import { theme } from 'antd';

import { StatisticsTableBlock } from '@Features/publisher/statistics/cpcStatistics';
// import { Space, Typography } from '@Shared/ui';
import { Space } from '@Shared/ui';

// const { Text } = Typography;

const CPCstatisticsTab = () => {
    // const {
    //     token: { fontSizeLG },
    // } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            {/* <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                В этом разделе вы видите статистику по каждому товару, который находится у вас на продвижении типа
                &quot;Оплата за клик&quot;
            </Text> */}
            <StatisticsTableBlock />
        </Space>
    );
};
export default CPCstatisticsTab;
