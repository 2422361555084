import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Space } from '@Shared/ui';
import {
    useGetPublisherCommissionsStatisticsQuery,
    PaymentTypeEnum,
} from '@Shared/api/rtkQueryApis/publisherStatistics/PublisherStatisticsApi';
import {
    FilterEnum,
    defaultFilterObject,
    FilterObjectType,
} from '@Features/publisher/statistics/cpcStatistics/model/types';
import { dayjs } from '@Shared/utils';
import { useUpdateSearchParams } from '@Shared/hooks';

import StatisticsTable from './statisticsTable/StatisticsTable';
import StatisticsFilters from './statisticsFilters/StatisticsFilters';

const initialFilterObject = (searchParams: URLSearchParams): FilterObjectType => ({
    page: searchParams.get(FilterEnum.PAGE) || defaultFilterObject.page,
    limit: searchParams.get(FilterEnum.LIMIT) || defaultFilterObject.limit,
    search_string: searchParams.get(FilterEnum.SEARCH_STRING) || '',
    has_commissions: Boolean(searchParams.get(FilterEnum.HAS_COMMISSIONS)),
    createdAtFrom: searchParams.get(FilterEnum.CREATED_AT_FROM) || '',
    createdAtTo: searchParams.get(FilterEnum.CREATED_AT_TO) || '',
});

const StatisticsTableBlock = () => {
    const [searchParams] = useSearchParams();
    const updateSearchParams = useUpdateSearchParams();
    const [filterObject, setFilterObject] = useState<FilterObjectType>(initialFilterObject(searchParams));
    const { data, isFetching } = useGetPublisherCommissionsStatisticsQuery(
        {
            page: filterObject.page,
            limit: filterObject.limit,
            dateFrom: filterObject.createdAtFrom || '',
            dateTo: filterObject.createdAtTo || '',
            searchString: filterObject?.search_string || '',
            paymentType: PaymentTypeEnum.Cpc,
            hasCommissions: filterObject?.has_commissions || undefined,
        },
        { skip: !filterObject.createdAtTo || !filterObject.createdAtFrom },
    );

    useEffect(() => {
        const filteredFilterObject = { ...filterObject };
        Object.keys(filteredFilterObject).forEach((param) => {
            if (filteredFilterObject[param] === defaultFilterObject[param]) {
                delete filteredFilterObject[param];
            }
        });
        updateSearchParams(filteredFilterObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject]);

    useEffect(() => {
        if (!filterObject.createdAtTo || !filterObject.createdAtFrom) {
            const endDate = dayjs().endOf('day').format('DD.MM.YYYY HH:mm:ss'); // Today's date at 23:59:00
            const startDate = dayjs().subtract(1, 'month').startOf('day').format('DD.MM.YYYY HH:mm:ss'); // One month ago at 00:00:00
            setFilterObject({
                ...filterObject,
                createdAtFrom: startDate,
                createdAtTo: endDate,
                page: 1,
            });
        }
    }, [filterObject]);

    return (
        <Space
            direction="vertical"
            size={24}
            style={{ width: '100%' }}
        >
            <StatisticsFilters
                filterObject={filterObject}
                setFilterObject={setFilterObject}
            />
            <StatisticsTable
                data={data}
                isLoading={isFetching}
                setFilterObject={setFilterObject}
            />
        </Space>
    );
};
export default StatisticsTableBlock;
