import { Navigate, createBrowserRouter } from 'react-router-dom';

import {
    DashboardPage,
    ProductsPage,
    StoresPage,
    SetupIntegrationPage,
    StoresTab,
    MarketplacesTab,
    ProductsTab,
    CampaignsTab,
    CampaignTypeSelectionPage,
    SetupCPAcampaignPage,
    SetupCPCcampaignPage,
    MessengerPage,
    NewMessageTab,
    MessagesTab,
    LegalInfoPage,
    FinancesPage,
    FinancesTab,
    ManualVerificationTab,
    CpcCommissionsTab,
    StatisticsPage,
    PromotionsPage,
    PromotionsTab,
    PersonalPromotionsTab,
    EditPromotionPage,
    EditPersonalPromotionPage,
    AddPersonalPromotionPage,
    SetupBarterCampaignPage,
    ProfilePage,
    ProfileTab,
    CPAstatisticsTab,
    CPCstatisticsTab,
    ClicksStatisticsTab,
} from '@Pages/seller';
import { ErrorPage } from '@Pages/errors';
import { AppLayout } from '@Widgets/seller/layouts';

import { public_routes } from './PublicRoutes';

export const seller_app_router = createBrowserRouter([
    ...public_routes,
    {
        path: '/',
        element: <AppLayout />,
        children: [
            // {
            //     path: '/',
            //     element: <DashboardPage />,
            // },
            {
                path: '/',
                element: (
                    <Navigate
                        replace
                        to="/stores"
                    />
                ),
            },
            {
                path: '/dashboard',
                element: <DashboardPage />,
            },
            {
                path: '/product-promotion',
                element: <ProductsPage />,
                children: [
                    {
                        path: '',
                        element: <ProductsTab />,
                    },
                    {
                        path: 'campaigns',
                        element: <CampaignsTab />,
                    },
                ],
            },
            {
                path: '/product-promotion/campaign-creation',
                element: <CampaignTypeSelectionPage />,
            },
            {
                path: '/product-promotion/setup-campaign/cpa/:slug?',
                element: <SetupCPAcampaignPage />,
            },
            {
                path: '/product-promotion/setup-campaign/cpc/:slug?',
                element: <SetupCPCcampaignPage />,
            },
            {
                path: '/product-promotion/setup-campaign/barter/:slug?',
                element: <SetupBarterCampaignPage />,
            },
            {
                path: '/stores',
                element: <StoresPage />,
                children: [
                    {
                        path: '',
                        element: <StoresTab />,
                    },
                    {
                        path: 'marketplaces',
                        element: <MarketplacesTab />,
                    },
                ],
            },
            {
                path: '/promotions',
                element: <PromotionsPage />,
                children: [
                    {
                        path: '',
                        element: <PromotionsTab />,
                    },
                    {
                        path: 'personal-promotions',
                        element: <PersonalPromotionsTab />,
                    },
                ],
            },
            {
                path: '/promotions/:promotionId/edit-promotion',
                element: <EditPromotionPage />,
            },
            {
                path: '/promotions/personal-promotions/add-personal-promotion',
                element: <AddPersonalPromotionPage />,
            },
            {
                path: '/promotions/personal-promotions/:promotionId/edit-promotion',
                element: <EditPersonalPromotionPage />,
            },
            {
                path: '/messenger',
                element: <MessengerPage />,
                children: [
                    {
                        path: '',
                        element: <MessagesTab />,
                    },
                    {
                        path: 'new',
                        element: <NewMessageTab />,
                    },
                ],
            },
            {
                path: '/stores/setup-integration',
                element: <SetupIntegrationPage />,
            },
            {
                path: '/legal-info',
                element: <LegalInfoPage />,
            },
            {
                path: '/profile',
                element: <ProfilePage />,
                children: [
                    {
                        path: '',
                        element: <ProfileTab />,
                    },
                ],
            },
            {
                path: '/finances',
                element: <FinancesPage />,
                children: [
                    {
                        path: '',
                        element: <FinancesTab />,
                    },
                    {
                        path: 'cpa-manual-verification',
                        element: <ManualVerificationTab />,
                    },
                    {
                        path: 'cpc-commissions',
                        element: <CpcCommissionsTab />,
                    },
                ],
            },
            {
                path: '/statistics',
                element: <StatisticsPage />,
                children: [
                    {
                        path: '',
                        element: <CPAstatisticsTab />,
                    },
                    {
                        path: 'cpc-commissions',
                        element: <CPCstatisticsTab />,
                    },
                    {
                        path: 'clicks',
                        element: <ClicksStatisticsTab />,
                    },
                ],
            },
            {
                path: '/404',
                element: <ErrorPage status={404} />,
            },
            {
                path: '*',
                element: (
                    <Navigate
                        replace
                        to="/404"
                    />
                ),
            },
        ],
    },
]);
