import { Row, Col, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/statistics/cpcStatistics/model/types';
import { DateRangeFilter, SearchFilter, CheckboxFilter } from '@Features/shared/filters';

// import ExportButton from './ExportButton';

const { useBreakpoint } = Grid;

type StatisticsFiltersProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
};

const StatisticsFilters = (props: StatisticsFiltersProps) => {
    const breakpoints = useBreakpoint();

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Col>
                <Row
                    wrap
                    align="middle"
                    style={{
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    <DateRangeFilter {...props} />
                    <SearchFilter<FilterObjectType>
                        {...props}
                        propName="search_string"
                        inputProps={{ placeholder: 'Артикул или наименование' }}
                    />
                    <CheckboxFilter<FilterObjectType>
                        {...props}
                        propName="has_commissions"
                        label="Не показывать с 0 комиссией"
                    />
                </Row>
            </Col>
            {/* <Col>
                <Row
                    justify="space-between"
                    align="middle"
                >
                    <ExportButton />
                </Row>
            </Col> */}
        </Row>
    );
};

export default StatisticsFilters;
