import { useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { theme } from 'antd';

import { Card, Space, Row, Typography, Button } from '@Shared/ui';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

import { type CampaignType } from './types';
import './styles.scss';

const { Title, Text } = Typography;

export const CampaignTypeCard = ({
    type: { disabled, title, icon, description, navigate: typeNavigate, hasButton, ymType },
}: {
    type: CampaignType;
}) => {
    const { data: user } = useGetTheUserInformationQuery();
    const {
        token: { colorTextDescription, fontSizeSM },
    } = theme.useToken();
    const navigate = useNavigate();

    const handleSetupCampaignClick = () => {
        if (typeNavigate) {
            navigate(typeNavigate);
        }
        sendYmEvent({
            name: 'click_set_up',
            o1: 'account;advertiser;ads campaign',
            o2: 'create ads',
            o4: ymType,
            advertiserId: user?.seller?.id,
            keycloakId: user?.mitgo,
            accountType: 'business',
        });
    };

    return (
        <Card
            bordered={false}
            className="campaign-type-selection__type-card"
        >
            <Space
                direction="vertical"
                align="start"
                size="small"
                className="campaign-type-selection__type-card__content"
            >
                <Space
                    direction="vertical"
                    align="start"
                    size="small"
                >
                    <span
                        style={{
                            color: disabled ? 'unset' : 'var(--ant-color-link)',
                            opacity: disabled ? '0.45' : '1',
                        }}
                    >
                        <Icon component={() => icon} />
                    </span>

                    <Row>
                        <Title
                            level={5}
                            style={{ margin: 0 }}
                        >
                            {title}
                        </Title>
                    </Row>
                    <Row>
                        <Text style={{ fontSize: fontSizeSM, color: colorTextDescription }}>{description}</Text>
                    </Row>
                </Space>
                {hasButton &&
                    (disabled ? (
                        <Button
                            type="dashed"
                            disabled
                        >
                            Скоро
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={handleSetupCampaignClick}
                        >
                            Настроить
                        </Button>
                    ))}
            </Space>
        </Card>
    );
};
