import { StarFilled } from '@ant-design/icons';

import { Button } from '@Shared/ui';
import {
    useAddAProductCampaignToFavoritesMutation,
    useRemoveProductCampaignFromFavoritesMutation,
} from '@Shared/api/rtkQueryApis/productCampaignFavorites';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

const FavoriteCell = ({
    isFavorite = false,
    productId,
    storeId,
}: {
    isFavorite: boolean;
    productId: string;
    storeId?: string;
}) => {
    const [addAProductCampaignToFavorites] = useAddAProductCampaignToFavoritesMutation();
    const [removeProductCampaignFromFavorites] = useRemoveProductCampaignFromFavoritesMutation();

    const { data: user } = useGetTheUserInformationQuery();

    const toggleProductFavorite = async (e) => {
        e.stopPropagation();
        if (isFavorite) {
            await removeProductCampaignFromFavorites({ productCampaign: productId });
        } else {
            sendYmEvent({
                name: 'click_add_to_wishlist',
                o1: 'account;publisher;catalog',
                o2: 'product catalog',
                publisherId: user?.publisher?.id,
                keycloakId: user?.mitgo,
                programId: storeId,
            });

            await addAProductCampaignToFavorites({ productCampaign: productId });
        }
    };

    return (
        <Button
            type="link"
            onClick={toggleProductFavorite}
            icon={<StarFilled style={{ color: isFavorite ? '#FADB14' : '#F5F5F5', fontSize: 20 }} />}
        />
    );
};

export default FavoriteCell;
