import Icon from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { Row, Grid, Button, Col } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/productPromotion/campaignsTab/campaignTypeSelection';
import { SearchFilter, CheckboxFilter } from '@Features/shared/filters';
import { PlusAdd } from '@Shared/assets/images/icons';
import { showModal } from '@Entities/modals/model/modalsSlice';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';

const { useBreakpoint } = Grid;

type PromotionsFilterProps = {
    filterObject: FilterObjectType;
    setFilterObject: (arg1: FilterObjectType) => void;
    campaignSlug: string;
    onImportClick: () => void;
};

const PromotionsFilters = ({ campaignSlug, onImportClick, ...props }: PromotionsFilterProps) => {
    const { data: user } = useGetTheUserInformationQuery();
    const breakpoints = useBreakpoint();
    const dispatch = useDispatch();

    const handleOpenImportModal = () => {
        onImportClick();
        dispatch(
            showModal({
                modalKey: ModalKeysType.importCampaignProducts,
                params: { campaignSlug },
            }),
        );
    };

    const YmSearchEvent = (value: string) => {
        sendYmEvent({
            name: 'search_search_filter',
            o1: 'account;advertiser;ads campaign',
            o2: 'ads campaign',
            o4: value,
            advertiserId: user?.seller?.id,
            keycloakId: user?.mitgo,
            accountType: 'business',
        });
    };

    return (
        <Row
            justify="space-between"
            style={{ gap: 8, flexDirection: breakpoints.md ? 'row' : 'column' }}
        >
            <Row
                wrap
                align="middle"
                style={{
                    gap: 12,
                    flexDirection: breakpoints.md ? 'row' : 'column',
                    alignItems: breakpoints.md ? 'center' : 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Col
                    style={{
                        display: 'flex',
                        gap: 12,
                        flexDirection: breakpoints.md ? 'row' : 'column',
                        alignItems: breakpoints.md ? 'center' : 'flex-start',
                    }}
                >
                    <SearchFilter<FilterObjectType>
                        {...props}
                        propName="search_string"
                        inputProps={{ placeholder: 'Артикул или наименование' }}
                        onChange={YmSearchEvent}
                    />
                    <CheckboxFilter<FilterObjectType>
                        label="Только продвигаемые товары"
                        propName="visible"
                        {...props}
                    />
                </Col>
                <Col>
                    <Button
                        icon={<Icon component={() => <PlusAdd />} />}
                        iconPosition="end"
                        onClick={handleOpenImportModal}
                        style={{
                            alignSelf: 'end',
                        }}
                    >
                        Настроить товары
                    </Button>
                </Col>
            </Row>
        </Row>
    );
};

export default PromotionsFilters;
