import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProductsListWithLinks: build.query<GetProductsListWithLinksApiResponse, GetProductsListWithLinksApiArg>({
            query: (queryArg) => ({
                url: '/api/publisher/v1/product/',
                params: {
                    source: queryArg.source,
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    search_string: queryArg.searchString,
                    stores: queryArg.stores,
                    marketplace_stores: queryArg.marketplaceStores,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    bids: queryArg.bids,
                    favorite: queryArg.favorite,
                    payment_type: queryArg.paymentType,
                },
            }),
        }),
        getAppPrivatePublisherProductcampaignProductlistexport: build.query<
            GetAppPrivatePublisherProductcampaignProductlistexportApiResponse,
            GetAppPrivatePublisherProductcampaignProductlistexportApiArg
        >({
            query: (queryArg) => ({
                url: '/api/publisher/v1/product/export/',
                params: {
                    source: queryArg.source,
                    search_string: queryArg.searchString,
                    stores: queryArg.stores,
                    marketplace_stores: queryArg.marketplaceStores,
                    marketplaces: queryArg.marketplaces,
                    categories: queryArg.categories,
                    bids: queryArg.bids,
                    favorite: queryArg.favorite,
                    payment_type: queryArg.paymentType,
                },
            }),
        }),
        getProductsDetailsWithLinks: build.query<
            GetProductsDetailsWithLinksApiResponse,
            GetProductsDetailsWithLinksApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/product/${queryArg.productCampaign}/`,
                params: { source: queryArg.source },
            }),
        }),
        getSourceContentTypesList: build.query<GetSourceContentTypesListApiResponse, GetSourceContentTypesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source-content-types/' }),
        }),
        getSourcesList: build.query<GetSourcesListApiResponse, GetSourcesListApiArg>({
            query: () => ({ url: '/api/publisher/v1/source/' }),
        }),
        addASource: build.mutation<AddASourceApiResponse, AddASourceApiArg>({
            query: (queryArg) => ({ url: '/api/publisher/v1/source/', method: 'POST', body: queryArg.sourceDto }),
        }),
        viewASource: build.query<ViewASourceApiResponse, ViewASourceApiArg>({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/` }),
        }),
        putAppPrivatePublisherSourceUpdate: build.mutation<
            PutAppPrivatePublisherSourceUpdateApiResponse,
            PutAppPrivatePublisherSourceUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/publisher/v1/source/${queryArg.source}/`,
                method: 'PUT',
                body: queryArg.sourceDto2,
            }),
        }),
        deleteAppPrivatePublisherSourceDelete: build.mutation<
            DeleteAppPrivatePublisherSourceDeleteApiResponse,
            DeleteAppPrivatePublisherSourceDeleteApiArg
        >({
            query: (queryArg) => ({ url: `/api/publisher/v1/source/${queryArg.source}/`, method: 'DELETE' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as PublisherSourcesApi };
export type GetProductsListWithLinksApiResponse = /** status 200 Get product campaign list with links */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductCampaignDto[];
};
export type GetProductsListWithLinksApiArg = {
    source?: number | null;
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum;
    searchString?: string | null;
    stores?: {
        ''?: string[];
    };
    marketplaceStores?: {
        ''?: string[];
    };
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    bids?: {
        ''?: string[];
    };
    favorite?: boolean;
    paymentType?: PaymentTypeEnum;
};
export type GetAppPrivatePublisherProductcampaignProductlistexportApiResponse = unknown;
export type GetAppPrivatePublisherProductcampaignProductlistexportApiArg = {
    source?: number | null;
    searchString?: string | null;
    stores?: {
        ''?: string[];
    };
    marketplaceStores?: {
        ''?: string[];
    };
    marketplaces?: {
        ''?: string[];
    };
    categories?: {
        ''?: number[];
    };
    bids?: {
        ''?: string[];
    };
    favorite?: boolean;
    paymentType?: PaymentTypeEnum;
};
export type GetProductsDetailsWithLinksApiResponse =
    /** status 200 Get products details with links */ ProductCampaignDto;
export type GetProductsDetailsWithLinksApiArg = {
    source?: number | null;
    productCampaign: string;
};
export type GetSourceContentTypesListApiResponse =
    /** status 200 Get source content types list */ SourceContentTypeList;
export type GetSourceContentTypesListApiArg = void;
export type GetSourcesListApiResponse = /** status 200 Get sources list */ SourceDtoList;
export type GetSourcesListApiArg = void;
export type AddASourceApiResponse = /** status 201 Resource created */ SourceDto3;
export type AddASourceApiArg = {
    sourceDto: SourceDto;
};
export type ViewASourceApiResponse = /** status 200 Resource response */ SourceDto3;
export type ViewASourceApiArg = {
    source: string;
};
export type PutAppPrivatePublisherSourceUpdateApiResponse = unknown;
export type PutAppPrivatePublisherSourceUpdateApiArg = {
    source: string;
    sourceDto2: SourceDto2;
};
export type DeleteAppPrivatePublisherSourceDeleteApiResponse = unknown;
export type DeleteAppPrivatePublisherSourceDeleteApiArg = {
    source: string;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace2 | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum5;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto3 = {
    id?: number | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
    publisher_fee?: number | null;
};
export type ProductCampaignDto = {
    id: string;
    campaign?: CampaignDto3 | null;
    product?: ProductDto | null;
    fee_setting?: FeeSettingDto | null;
    visible?: boolean;
    favorite?: boolean;
    tracking_link?: string | null;
    legal_text?: string | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
    tracking_short_link?: string | null;
};
export type SourceContentType = {
    id: number;
    title: string;
    slug: string;
};
export type SourceContentTypeList = {
    items?: SourceContentType[];
};
export type Publisher2 = object;
export type SourceDto3 = {
    id?: string | null;
    publisher?: Publisher2 | null;
    title: string | null;
    status?: SourceStatusEnum | null;
    source_type: number;
    source_content_types?: number[];
    source_url?: string | null;
    comment?: string | null;
};
export type SourceDtoList = {
    items?: SourceDto3[];
};
export type SourceDto = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url?: string | null;
};
export type SourceDto2 = {
    title: string | null;
    source_type: number;
    source_content_types?: number[];
    source_url?: string | null;
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum PaymentTypeEnum5 {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
    Cpmc = 'cpmc',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
    Disconnected = 'disconnected',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum SortOrderEnum {
    Asc = 'asc',
    Desc = 'desc',
}
export enum PaymentTypeEnum {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
    Cpmc = 'cpmc',
}
export enum SourceStatusEnum {
    Verification = 'verification',
    Approved = 'approved',
    Declined = 'declined',
}
export const {
    useGetProductsListWithLinksQuery,
    useGetAppPrivatePublisherProductcampaignProductlistexportQuery,
    useGetProductsDetailsWithLinksQuery,
    useGetSourceContentTypesListQuery,
    useGetSourcesListQuery,
    useAddASourceMutation,
    useViewASourceQuery,
    usePutAppPrivatePublisherSourceUpdateMutation,
    useDeleteAppPrivatePublisherSourceDeleteMutation,
} = injectedRtkApi;
