import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, QuestionTooltip } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/promotions/promotionsTab/model/types';
import {
    GetProductsListForPromotionApiResponse,
    PromotionProductDto2,
} from '@Shared/api/rtkQueryApis/sellerPromotions';
import { ProductNameCell } from '@Features/shared/TableCells';

import { PriceDiscountCell } from './cells';

type PromotionProductsTableProps = {
    data: GetProductsListForPromotionApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text, Link } = Typography;

const columns = [
    {
        title: 'Название товара',
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        render: (_: unknown, { product }: PromotionProductDto2) => (
            <ProductNameCell
                product={product}
                showCategory
            />
        ),
    },
    {
        title: (
            <>
                Стоимость &nbsp;
                <QuestionTooltip
                    title="Цена товара на маркетплейсе может отличаться.
                 При расчете комиссии учитывается актуальная информация о товаре"
                />
            </>
        ),
        dataIndex: 'price',
        key: 'price',
        render: (_: unknown, promotionProduct: PromotionProductDto2) => (
            <PriceDiscountCell promotionProduct={promotionProduct} />
        ),
    },
    {
        title: 'Скидка',
        dataIndex: 'price_percent',
        key: 'price_percent',
        render: (price_percent: Pick<PromotionProductDto2, 'price_percent'>) => `${price_percent || 0}%`,
    },
    {
        title: 'Ссылка на товар',
        dataIndex: 'external_link',
        key: 'external_link',
        ellipsis: true,
        render: (_: unknown, { product }: PromotionProductDto2) => (
            <Link
                target="_blank"
                href={product.external_link}
            >
                {product.external_link}
            </Link>
        ),
    },
];

const PersonalPromotionProductsTable = ({ data, isLoading, setFilterObject }: PromotionProductsTableProps) => {
    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: <Text>Нет продуктов для выбора</Text>,
                }),
            }}
        />
    );
};

export default PersonalPromotionProductsTable;
