import { Dispatch, SetStateAction, useMemo } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography, QuestionTooltip, Grid } from '@Shared/ui';
import { FilterObjectType } from '@Features/publisher/promotions/promotionsTab/model/types';
import {
    GetGlobalPromotionsProductsListWithLinksBySourceApiResponse,
    PromotionProductDto,
} from '@Shared/api/rtkQueryApis/publisherPromotions';
import { ProductNameCell } from '@Features/shared/TableCells';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';

import { MPTitleCell, PeriodCell, PriceDiscountCell, FavoriteCell, TrackingLinksCell } from './cells';

const { useBreakpoint } = Grid;

import './styles.scss';

type PromotionsTableProps = {
    data: GetGlobalPromotionsProductsListWithLinksBySourceApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
    setPromotionId: Dispatch<SetStateAction<string>>;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    selectedSourceId: string;
};

const { Text } = Typography;

const PromotionsTable = ({
    data,
    isLoading,
    setFilterObject,
    setPromotionId,
    setDrawerOpen,
    selectedSourceId,
}: PromotionsTableProps) => {
    const breakpoints = useBreakpoint();
    const { data: user } = useGetTheUserInformationQuery();

    const columns = useMemo(
        () => [
            {
                width: 48,
                render: (_: unknown, promotionProduct: PromotionProductDto) => (
                    <FavoriteCell
                        isFavorite={!!promotionProduct.favorite}
                        promotionProductId={promotionProduct.id}
                    />
                ),
            },
            {
                title: 'Название товара',
                dataIndex: 'title',
                key: 'title',
                ellipsis: true,
                width: 260,
                render: (_: unknown, promotionProduct: PromotionProductDto) => (
                    <ProductNameCell
                        product={promotionProduct.product}
                        showCategory
                    />
                ),
            },
            {
                title: (
                    <>
                        Стоимость &nbsp;
                        <QuestionTooltip
                            title="Цена товара на маркетплейсе может отличаться.
                         При расчете комиссии учитывается актуальная информация о товаре"
                        />
                    </>
                ),
                dataIndex: 'price',
                key: 'price',
                render: (_: unknown, promotionProduct: PromotionProductDto) => (
                    <PriceDiscountCell promotionProduct={promotionProduct} />
                ),
            },
            {
                title: 'Скидка',
                dataIndex: 'price_percent',
                width: 100,
                key: 'price_percent',
                render: (price_percent: Pick<PromotionProductDto, 'price_percent'>) => `${price_percent}%`,
            },
            {
                title: 'Период акции',
                dataIndex: 'start_date',
                key: 'start_date',
                render: (_: unknown, promotionProduct: PromotionProductDto) => (
                    <PeriodCell promotion={promotionProduct.promotion} />
                ),
            },
            {
                title: 'Комиссия',
                dataIndex: 'start_date',
                key: 'start_date',
                width: 120,
                render: (_: unknown, promotionProduct: PromotionProductDto) =>
                    `${Math.floor((promotionProduct.fee_setting?.fee_value || 0) * 100)}%`,
            },
            {
                title: 'Магазин',
                dataIndex: 'marketplace_store',
                key: 'marketplace_store',
                render: (_: unknown, promotionProduct: PromotionProductDto) => (
                    <MPTitleCell marketplaceStore={promotionProduct.product?.marketplace_store} />
                ),
            },
            {
                title: 'Партнерская ссылка',
                dataIndex: 'link',
                width: breakpoints.sm ? 265 : 140,
                key: 'link',
                fixed: 'right',
                render: (_: unknown, promotionProduct: PromotionProductDto) => (
                    <TrackingLinksCell promotionProduct={promotionProduct} />
                ),
            },
        ],
        [breakpoints],
    );

    return (
        <Table
            scroll={{ x: '1300px' }}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            // showHeader={!!data?.items?.length}
            rowClassName={(record) => (record.favorite ? 'promotion-products-table__favorite' : '')}
            onRow={(record) => {
                return {
                    onClick: () => {
                        setPromotionId(record.id);
                        setDrawerOpen(true);
                        sendYmEvent({
                            name: 'click_item',
                            o1: 'account;publisher;promotions and promocodes',
                            o2: 'from marketplace',
                            programId: record?.promotion?.id || '',
                            publisherId: user?.publisher?.id,
                            keycloakId: user?.mitgo,
                        });
                    },
                };
            }}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: (
                        <Text>
                            {selectedSourceId ? 'Нет доступных скидок' : 'Чтобы продолжить работу выберите прощадку'}
                        </Text>
                    ),
                }),
            }}
        />
    );
};

export default PromotionsTable;
