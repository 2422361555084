export const formatFileSize = (fileSize: number) => {
    if (typeof fileSize !== 'number' || fileSize < 0) {
        throw new Error('Размер файла должен быть положительным числом');
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;

    while (fileSize >= 1024 && index < units.length - 1) {
        fileSize /= 1024;
        index++;
    }

    return `${fileSize.toFixed(index === 0 ? 0 : 1)} ${units[index]}`;
};
