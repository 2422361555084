import { theme } from 'antd';

import { StatisticsTableBlock } from '@Features/seller/statistics/clicksStatistics';
import { Space, Typography } from '@Shared/ui';

const { Text } = Typography;

const ClickStatisticsTab = () => {
    const {
        token: { fontSizeLG },
    } = theme.useToken();
    return (
        <Space
            direction="vertical"
            style={{ width: '100%' }}
            size={24}
        >
            <Text
                type="secondary"
                style={{ fontSize: fontSizeLG }}
            >
                В этом разделе вы видите статистику по количеству кликов
            </Text>
            <StatisticsTableBlock />
        </Space>
    );
};
export default ClickStatisticsTab;
