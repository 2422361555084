import { Dispatch, SetStateAction } from 'react';

import { renderEmpty } from '@Features/shared';
import { Table, Typography } from '@Shared/ui';
import { FilterObjectType } from '@Features/seller/finances/manualVerification/model/types';
import { formatDate } from '@Shared/utils';
import {
    GetSellerCommissionsListApiResponse,
    CommissionDto,
} from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';

import ReasonCell from './ReasonCell';
import TotalCartCell from './TotalCartCell';
import CommissionCell from './CommissionCell';
import ActionsMenu from './ActionsMenu';

type ManualVerificationTableProps = {
    data: GetSellerCommissionsListApiResponse | undefined;
    isLoading: boolean;
    setFilterObject: Dispatch<SetStateAction<FilterObjectType>>;
};

const { Text, Link } = Typography;

const columns = [
    {
        title: 'Дата',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        width: 150,
        render: (_, { mp_traffic_report }: CommissionDto) => <>{formatDate(mp_traffic_report?.date || '')}</>,
    },
    {
        title: 'Магазин',
        key: 'store',
        render: (_, { marketplace_store }: CommissionDto) => marketplace_store?.store?.title || '',
    },
    {
        title: 'Ссылка на МП',
        key: 'external_link',
        ellipsis: true,
        render: (_, { order_product }: CommissionDto) => (
            <Link
                href={order_product?.external_link || ''}
                target="_blank"
            >
                {order_product?.external_link || ''}
            </Link>
        ),
    },
    {
        title: 'Сумма заказа',
        dataIndex: 'total_cart',
        key: 'total_cart',
        render: (_, comission: CommissionDto) => <TotalCartCell commission={comission} />,
    },
    {
        title: 'Комиссия',
        dataIndex: 'total_fee',
        key: 'total_fee',
        render: (_, comission: CommissionDto) => <CommissionCell commission={comission} />,
    },
    {
        title: 'Причина',
        dataIndex: 'decline_reason',
        key: 'decline_reason',
        render: (_, commission: CommissionDto) => <ReasonCell commission={commission} />,
    },
    {
        key: 'actionMenu',
        align: 'center',
        width: 140,
        render: (_, commission: CommissionDto) => <ActionsMenu commission={commission} />,
    },
];

const ManualVerificationTable = ({ data, isLoading, setFilterObject }: ManualVerificationTableProps) => {
    return (
        <Table
            scroll={{ x: '1000px' }}
            dataSource={data?.items}
            rowKey="id"
            columns={columns}
            // showHeader={!!data?.items?.length}
            loading={isLoading}
            pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                pageSizeOptions: ['10', '25', '50'],
                pageSize: data?.limit,
                position: ['bottomLeft'],
                current: data?.page,
                total: data?.totalCount,
                onChange: (page, pageSize) =>
                    setFilterObject((prevFilterObject) => {
                        const hasPageSizeChanged = pageSize !== +prevFilterObject.limit;
                        return {
                            ...prevFilterObject,
                            page: hasPageSizeChanged ? 1 : page,
                            limit: pageSize,
                        };
                    }),
            }}
            locale={{
                emptyText: renderEmpty({
                    content: (
                        <Text>
                            Сейчас у вас нет неподтвержденных комиссий за покупки. <br /> Если вы считаете, что это
                            ошибка, свяжитесь с<Link href={`mailto:${process.env.SUPPORT_EMAIL}`}> Поддержкой</Link>.
                        </Text>
                    ),
                }),
            }}
        />
    );
};

export default ManualVerificationTable;
