import { emptySplitApi as api } from '../../full/emptyApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        addACpcCampaign: build.mutation<AddACpcCampaignApiResponse, AddACpcCampaignApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/cpc/',
                method: 'POST',
                body: queryArg.cpcCampaignDto,
            }),
        }),
        putAppPrivateSellerCampaignUpdatecpc: build.mutation<
            PutAppPrivateSellerCampaignUpdatecpcApiResponse,
            PutAppPrivateSellerCampaignUpdatecpcApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/cpc/${queryArg.slug}/`,
                method: 'PUT',
                body: queryArg.cpcCampaignDto2,
            }),
        }),
        addABarterCampaign: build.mutation<AddABarterCampaignApiResponse, AddABarterCampaignApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/barter/',
                method: 'POST',
                body: queryArg.barterCampaignDto,
            }),
        }),
        putAppPrivateSellerCampaignUpdatebarter: build.mutation<
            PutAppPrivateSellerCampaignUpdatebarterApiResponse,
            PutAppPrivateSellerCampaignUpdatebarterApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/barter/${queryArg.slug}/`,
                method: 'PUT',
                body: queryArg.barterCampaignDto2,
            }),
        }),
        patchAppPrivateSellerCampaignActivate: build.mutation<
            PatchAppPrivateSellerCampaignActivateApiResponse,
            PatchAppPrivateSellerCampaignActivateApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/activate/`, method: 'PATCH' }),
        }),
        patchAppPrivateSellerCampaignDisable: build.mutation<
            PatchAppPrivateSellerCampaignDisableApiResponse,
            PatchAppPrivateSellerCampaignDisableApiArg
        >({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/disable/`, method: 'PATCH' }),
        }),
        viewTheCampaign: build.query<ViewTheCampaignApiResponse, ViewTheCampaignApiArg>({
            query: (queryArg) => ({ url: `/api/seller/v1/campaign/${queryArg.slug}/` }),
        }),
        getCampaignsList: build.query<GetCampaignsListApiResponse, GetCampaignsListApiArg>({
            query: (queryArg) => ({
                url: '/api/seller/v1/campaign/',
                params: { payment_types: queryArg.paymentTypes },
            }),
        }),
        patchAppPrivateSellerCampaignUpdate: build.mutation<
            PatchAppPrivateSellerCampaignUpdateApiResponse,
            PatchAppPrivateSellerCampaignUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/cpa/${queryArg.slug}/`,
                method: 'PATCH',
                body: queryArg.campaignDto,
            }),
        }),
        getProductsListForCampaign: build.query<
            GetProductsListForCampaignApiResponse,
            GetProductsListForCampaignApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/`,
                params: {
                    page: queryArg.page,
                    limit: queryArg.limit,
                    sortField: queryArg.sortField,
                    sortOrder: queryArg.sortOrder,
                    search_string: queryArg.searchString,
                    visible: queryArg.visible,
                },
            }),
        }),
        patchAppPrivateSellerCampaignChangecommission: build.mutation<
            PatchAppPrivateSellerCampaignChangecommissionApiResponse,
            PatchAppPrivateSellerCampaignChangecommissionApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/change-commission/`,
                method: 'PATCH',
                body: queryArg.changeCommissionDto,
            }),
        }),
        patchAppPrivateSellerCampaignSetvisibility: build.mutation<
            PatchAppPrivateSellerCampaignSetvisibilityApiResponse,
            PatchAppPrivateSellerCampaignSetvisibilityApiArg
        >({
            query: (queryArg) => ({
                url: `/api/seller/v1/campaign/${queryArg.slug}/product/set-visibility/`,
                method: 'PATCH',
                body: queryArg.setVisibilityDto,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as SellerCampaignsApi };
export type AddACpcCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type AddACpcCampaignApiArg = {
    cpcCampaignDto: CpcCampaignDto;
};
export type PutAppPrivateSellerCampaignUpdatecpcApiResponse = unknown;
export type PutAppPrivateSellerCampaignUpdatecpcApiArg = {
    slug: string;
    cpcCampaignDto2: CpcCampaignDto2;
};
export type AddABarterCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type AddABarterCampaignApiArg = {
    barterCampaignDto: BarterCampaignDto;
};
export type PutAppPrivateSellerCampaignUpdatebarterApiResponse = unknown;
export type PutAppPrivateSellerCampaignUpdatebarterApiArg = {
    slug: string;
    barterCampaignDto2: BarterCampaignDto2;
};
export type PatchAppPrivateSellerCampaignActivateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignActivateApiArg = {
    slug: string;
};
export type PatchAppPrivateSellerCampaignDisableApiResponse = unknown;
export type PatchAppPrivateSellerCampaignDisableApiArg = {
    slug: string;
};
export type ViewTheCampaignApiResponse = /** status 200 Resource response */ CampaignDto2;
export type ViewTheCampaignApiArg = {
    slug: string;
};
export type GetCampaignsListApiResponse = /** status 200 Get campaigns list */ CampaignDtoList;
export type GetCampaignsListApiArg = {
    paymentTypes?: {
        ''?: number[];
    };
};
export type PatchAppPrivateSellerCampaignUpdateApiResponse = unknown;
export type PatchAppPrivateSellerCampaignUpdateApiArg = {
    slug: string;
    campaignDto: CampaignDto;
};
export type GetProductsListForCampaignApiResponse = /** status 200 Get products list for campaign */ {
    totalCount?: number;
    page?: number;
    limit?: number;
    items?: ProductCampaign[];
};
export type GetProductsListForCampaignApiArg = {
    slug: string;
    page?: number;
    limit?: number;
    sortField?: string | null;
    sortOrder?: SortOrderEnum;
    searchString?: string | null;
    visible?: boolean | null;
};
export type PatchAppPrivateSellerCampaignChangecommissionApiResponse = unknown;
export type PatchAppPrivateSellerCampaignChangecommissionApiArg = {
    slug: string;
    changeCommissionDto: ChangeCommissionDto;
};
export type PatchAppPrivateSellerCampaignSetvisibilityApiResponse = unknown;
export type PatchAppPrivateSellerCampaignSetvisibilityApiArg = {
    slug: string;
    setVisibilityDto: SetVisibilityDto;
};
export type Store2 = {
    id: string;
    title: string;
    logo?: string | null;
};
export type Marketplace2 = {
    id: string;
    title: string;
    slug: MarketPlaceSlugEnum4;
    description?: string | null;
};
export type MarketplaceStore3 = {
    id: string;
    store?: Store2 | null;
    marketplace?: Marketplace2 | null;
};
export type PaymentType = {
    id: number;
    title: string;
    type: PaymentTypeEnum5;
};
export type FeeSetting2 = {
    id: string;
    fee_type?: FeeTypeEnum;
    fee_value?: string;
    system_rate?: string;
    min_total_fee?: string;
};
export type BarterSettingDto3 = {
    id?: string | null;
    advertising_format?: AdvertisingFormatEnum3[];
    integration_number: IntegrationNumberEnum3 | null;
    barter_fee?: number | null;
    payment_type: PaymentTypeEnum6 | null;
    deadline: DeadlineEnum3 | null;
    publisher_requirements: string | null;
};
export type SourceCampaignFee = {
    id: string;
};
export type CampaignDto3 = {
    id?: number | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    fee_setting: FeeSetting2 | null;
    source_campaign_fees?: SourceCampaignFee[];
};
export type Currency = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory = {
    id: number;
    title: string;
};
export type ProductDto = {
    id: string;
    marketplace_store: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: number | null;
    currency: Currency | null;
    product_category?: ProductCategory | null;
    external_link: string;
    external_id: string;
    image_url?: string | null;
};
export type FeeSettingDto = {
    fee_type?: FeeTypeEnum | null;
    fee_value?: number | null;
    publisher_fee?: number | null;
};
export type ProductCampaignDto = {
    id: string;
    campaign?: CampaignDto3 | null;
    product?: ProductDto | null;
    fee_setting?: FeeSettingDto | null;
    visible?: boolean;
    favorite?: boolean;
    tracking_link?: string | null;
    legal_text?: string | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
    tracking_short_link?: string | null;
};
export type CampaignDto2 = {
    slug?: string | null;
    marketplace_store?: MarketplaceStore3 | null;
    title: string | null;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum | null;
    hold_time: number | null;
    daily_budget?: number | null;
    fee_setting: FeeSetting2 | null;
    barter_setting?: BarterSettingDto3 | null;
    product_campaigns?: ProductCampaignDto[];
    source_campaign_fees?: SourceCampaignFee[];
};
export type Store = {
    title: string;
    logo?: string | null;
};
export type Marketplace = {
    title: string;
    slug: MarketPlaceSlugEnum2;
    description?: string | null;
};
export type MarketplaceStore2 = {
    id: string;
    store?: Store | null;
    marketplace?: Marketplace | null;
};
export type CpcFeeSettingDto = {
    fee_value: number | null;
};
export type Currency2 = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory2 = {
    title: string;
};
export type Product2 = {
    id: number;
    marketplace_store?: MarketplaceStore2 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency2 | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory2 | null;
};
export type CpcProductCampaignDto = {
    product?: Product2 | null;
    commission?: number | null;
};
export type CpcCampaignDto = {
    marketplace_store: MarketplaceStore2 | null;
    title: string | null;
    fee_setting: CpcFeeSettingDto | null;
    daily_budget: number | null;
    product_campaigns?: CpcProductCampaignDto[];
};
export type CpcFeeSettingDto2 = {
    fee_value: number | null;
};
export type Store4 = {
    title: string;
    logo?: string | null;
};
export type Marketplace3 = {
    title: string;
    slug: MarketPlaceSlugEnum5;
    description?: string | null;
};
export type MarketplaceStore4 = {
    id: string;
    store?: Store4 | null;
    marketplace?: Marketplace3 | null;
};
export type Currency3 = {
    shortCode: string;
    symbol: string;
};
export type ProductCategory3 = {
    title: string;
};
export type Product3 = {
    id: number;
    marketplace_store?: MarketplaceStore4 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency3 | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory3 | null;
};
export type CpcProductCampaignDto2 = {
    product?: Product3 | null;
    commission?: number | null;
};
export type CpcCampaignDto2 = {
    title: string | null;
    fee_setting: CpcFeeSettingDto2 | null;
    daily_budget: number | null;
    product_campaigns?: CpcProductCampaignDto2[];
};
export type BarterSettingDto = {
    advertising_format?: AdvertisingFormatEnum[];
    integration_number: IntegrationNumberEnum | null;
    payment_type: PaymentTypeEnum3 | null;
    deadline: DeadlineEnum | null;
    publisher_requirements: string | null;
};
export type BarterProductCampaignDto = {
    product?: Product2 | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
};
export type BarterCampaignDto = {
    marketplace_store: MarketplaceStore2 | null;
    title: string | null;
    barter_setting: BarterSettingDto | null;
    product_campaigns?: BarterProductCampaignDto[];
};
export type BarterSettingDto2 = {
    advertising_format?: AdvertisingFormatEnum2[];
    integration_number: IntegrationNumberEnum2 | null;
    payment_type: PaymentTypeEnum4 | null;
    deadline: DeadlineEnum2 | null;
    publisher_requirements: string | null;
};
export type BarterProductCampaignDto2 = {
    product?: Product3 | null;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
};
export type BarterCampaignDto2 = {
    title: string | null;
    barter_setting: BarterSettingDto2 | null;
    product_campaigns?: BarterProductCampaignDto2[];
};
export type CampaignDtoList = {
    items?: CampaignDto2[];
};
export type FeeSetting = {
    fee_value?: string;
};
export type CampaignDto = {
    title: string | null;
    hold_time: number | null;
    fee_setting: FeeSetting | null;
};
export type BarterSetting = object;
export type Campaign = {
    id: number;
    title: string;
    slug: string;
    description?: string | null;
    logo?: string | null;
    marketplace_url?: string | null;
    payment_type?: PaymentType | null;
    status?: CampaignStatusEnum;
    holdTime?: number;
    fee_setting?: FeeSetting2 | null;
    barter_setting?: BarterSetting | null;
};
export type Product = {
    id: number;
    marketplace_store?: MarketplaceStore3 | null;
    title: string;
    sku: string;
    description: string;
    price: string;
    currency?: Currency | null;
    external_link: string;
    external_id: string;
    product_category?: ProductCategory | null;
    image_url?: string | null;
};
export type ProductCampaign = {
    id: string;
    campaign: Campaign;
    product: Product;
    fee_setting?: FeeSetting2 | null;
    visible?: boolean;
    favorite?: boolean;
    advertising_guidance?: string | null;
    need_review?: boolean;
    keywords?: string | null;
};
export type ChangeCommissionDto = {
    select_all?: boolean;
    commission: number | null;
    products?: string[];
};
export type SetVisibilityDto = {
    select_all?: boolean;
    visible?: boolean;
    products?: string[];
};
export enum MarketPlaceSlugEnum4 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum PaymentTypeEnum5 {
    Cpa = 'cpa',
    Cpp = 'cpp',
    Barter = 'barter',
    Cpc = 'cpc',
    Cpmc = 'cpmc',
}
export enum CampaignStatusEnum {
    Pending = 'pending',
    Active = 'active',
    TopUpBalance = 'top_up_balance',
    AddLegalInfo = 'add_legal_info',
    Verifying = 'verifying',
    Disabled = 'disabled',
    Disconnected = 'disconnected',
}
export enum FeeTypeEnum {
    Fixed = 'fixed',
    Percentage = 'percentage',
}
export enum AdvertisingFormatEnum3 {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum3 {
    $30 = '30',
    $60 = '60',
    $90 = '90',
}
export enum PaymentTypeEnum6 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum3 {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum MarketPlaceSlugEnum2 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum MarketPlaceSlugEnum5 {
    Ozon = 'ozon',
    Wildberries = 'wildberries',
    Avito = 'avito',
}
export enum AdvertisingFormatEnum {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum {
    $30 = '30',
    $60 = '60',
    $90 = '90',
}
export enum PaymentTypeEnum3 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum AdvertisingFormatEnum2 {
    Reels = 'reels',
    Stories = 'stories',
    Post = 'post',
}
export enum IntegrationNumberEnum2 {
    $30 = '30',
    $60 = '60',
    $90 = '90',
}
export enum PaymentTypeEnum4 {
    TransferToCard = 'transfer_to_card',
    Certificate = 'certificate',
    PromoCode = 'promo_code',
}
export enum DeadlineEnum2 {
    Month1 = 'month_1',
    Month2 = 'month_2',
    Month3 = 'month_3',
}
export enum SortOrderEnum {
    Asc = 'asc',
    Desc = 'desc',
}
export const {
    useAddACpcCampaignMutation,
    usePutAppPrivateSellerCampaignUpdatecpcMutation,
    useAddABarterCampaignMutation,
    usePutAppPrivateSellerCampaignUpdatebarterMutation,
    usePatchAppPrivateSellerCampaignActivateMutation,
    usePatchAppPrivateSellerCampaignDisableMutation,
    useViewTheCampaignQuery,
    useGetCampaignsListQuery,
    usePatchAppPrivateSellerCampaignUpdateMutation,
    useGetProductsListForCampaignQuery,
    usePatchAppPrivateSellerCampaignChangecommissionMutation,
    usePatchAppPrivateSellerCampaignSetvisibilityMutation,
} = injectedRtkApi;
