import { PublisherPromotionsApi } from './PublisherPromotionsApi';

PublisherPromotionsApi.enhanceEndpoints({
    addTagTypes: ['PublisherGlobalPromotionProductsList', 'PublisherPromotionProductsList'],
    endpoints: {
        getGlobalPromotionsProductsListWithLinksBySource: {
            providesTags: ['PublisherGlobalPromotionProductsList'],
        },
        addAGlobalPromotionProductToFavorites: {
            invalidatesTags: ['PublisherGlobalPromotionProductsList'],
        },
        removeGlobalPromotionProductFromFavorites: {
            invalidatesTags: ['PublisherGlobalPromotionProductsList'],
        },
        getPromotionsListWithLinksBySource: {
            providesTags: ['PublisherPromotionProductsList'],
        },
        addAPromotionToFavorites: {
            invalidatesTags: ['PublisherPromotionProductsList'],
        },
        removePromotionFromFavorites: {
            invalidatesTags: ['PublisherPromotionProductsList'],
        },
    },
});

export * from './PublisherPromotionsApi';
