import { theme } from 'antd';

import { Typography } from '@Shared/ui';
import { CommissionDto } from '@Shared/api/rtkQueryApis/sellerCommissions/SellerCommissionsApi';
import { CommissionStatusEnum } from '@Shared/api/rtkQueryApis/sellerCommissions';

const { Text } = Typography;

const ClickCountCell = ({ commission }: { commission: CommissionDto }) => {
    const {
        token: { colorTextTertiary, colorText },
    } = theme.useToken();

    return (
        <Text style={{ color: commission?.status === CommissionStatusEnum.Declined ? colorTextTertiary : colorText }}>
            {commission?.cpc_commission_detail?.clickCount || 0}
        </Text>
    );
};

export default ClickCountCell;
