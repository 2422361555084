import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { theme } from 'antd';

import { Button, Form, Input, Grid, Popover, Typography, Divider } from '@Shared/ui';
import { ConfirmLeavePrompt } from '@Features/shared';
import { showNotification, NotificationType } from '@Entities/notifications/model/notificationSlice';
import { useGetIntegrationDataFromExtension } from '@Shared/hooks';
import { sendYmEvent } from '@Shared/utils/analytics/sendYmEvent';
import { useGetTheUserInformationQuery } from '@Shared/api/rtkQueryApis/user';
import { useConnectMarketplaceToWildberriesMutation } from '@Shared/api/rtkQueryApis/sellerIntegrations';
import { normalizeErrorsObject } from '@Shared/utils/normalizeErrorsObject';

import './styles.scss';

const { useBreakpoint } = Grid;
const { Link, Text } = Typography;

const fieldsHelperText = {
    api_authorization_token: (
        <>
            {'Создайте новый токен в разделе: '}
            <Link
                href="https://seller.wildberries.ru/supplier-settings/access-to-api"
                target="_blank"
            >
                Профиль --&gt; Настройки --&gt; Доступ к API
            </Link>
            {' с доступом к разделам '}
            <strong>Цены и Контент </strong>
            {'и скопируйте в это поле полученный код'}
        </>
    ),
    wb_token_v3: (
        <>
            {'Скопируйте в это поле '}
            <strong>Cookie Value </strong>
            {'параметра '}
            <strong>WBTokenV3 </strong>
            {'из файлов Cookie. Подробнее '}
            <Link
                href="https://docs.google.com/document/d/1Fd14LnRYRoCNI7qp9BF1JqYJW0X0lCnK-Xleb7PnWTA/edit"
                target="_blank"
            >
                здесь
            </Link>
        </>
    ),
};

type SetupIntegrationFormProps = {
    storeId: string;
};

const WildberriesIntegrationForm = ({ storeId }: SetupIntegrationFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const breakpoints = useBreakpoint();
    const [form] = Form.useForm();
    useGetIntegrationDataFromExtension(form);
    const [hasUnsavedChanges, setUnsavedChanges] = useState(false);
    const { data: user } = useGetTheUserInformationQuery();
    const [addIntegration, { isLoading: isAdding, error: isAddingError }] =
        useConnectMarketplaceToWildberriesMutation();

    const {
        token: { colorTextDescription },
    } = theme.useToken();

    const handleSubmit = async (formValues) => {
        try {
            await addIntegration({
                wildberriesIntegrationDto: formValues,
                notificationOptions: {
                    successMessage: 'Интеграция была успешно создана',
                    errorMessage: 'Ошибка при попытке создания интеграции',
                },
                store: storeId,
            }).unwrap();
            sendYmEvent({
                name: 'send_set_integration_success',
                o1: 'account;advertiser;store',
                o2: 'marketplaces',
                o4: 'ozon',
                advcampaignId: storeId,
                keycloakId: user?.mitgo,
                advertiserId: user?.seller?.id,
            });

            setTimeout(() =>
                dispatch(
                    showNotification({
                        componentProps: {
                            type: NotificationType.Info,
                            message: 'У вас появилась новая рекламная кампания',
                            description:
                                // eslint-disable-next-line max-len
                                'Проверьте и отредактируйте информацию о ней. Запустите кампанию, чтобы начать быстрое продвижение товаров.',
                            btn: (
                                <Button
                                    type="link"
                                    onClick={() => navigate('/product-promotion/campaigns')}
                                >
                                    Рекламные кампании
                                </Button>
                            ),
                        },
                    }),
                    1000,
                ),
            );
            handleBack();
        } catch (err) {
            console.error(err);
        }
    };

    const onFinish = (formData) => {
        handleSubmit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    useEffect(() => {
        const errors = normalizeErrorsObject(isAddingError?.data?.errors);
        const formErrors = Object.keys(errors).map((field) => ({ name: field, errors: [errors[field]] }));
        form.setFields(formErrors);
    }, [isAddingError, form]);

    useEffect(() => {
        if (isAdding) {
            setUnsavedChanges(false);
        }
    }, [isAdding]);

    const renderTooltip = (field) => (
        <Popover
            content={<div style={{ maxWidth: 500 }}>{fieldsHelperText[field]}</div>}
            placement={breakpoints.md ? 'right' : 'top'}
        >
            <QuestionCircleOutlined style={{ marginLeft: 5, color: colorTextDescription }} />
        </Popover>
    );

    return (
        <Form
            form={form}
            name="integration-wildberries"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={() => setUnsavedChanges(true)}
            autoComplete="off"
            className="setup-integration-page__form"
            layout={breakpoints.lg ? 'horizontal' : 'vertical'}
        >
            <ConfirmLeavePrompt
                hasUnsavedChanges={hasUnsavedChanges}
                allowsPaths={['/stores/setup-integration']}
            />
            <Form.Item
                name="api_authorization_token"
                label={<>API authorization token {renderTooltip('api_authorization_token')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите API authorization token" />
            </Form.Item>
            <Form.Item
                name="wb_token_v3"
                label={<>WBTokenV3 {renderTooltip('wb_token_v3')}</>}
                validateTrigger="onBlur"
                rules={[{ required: true, message: 'Это поле обязательно к заполнению' }]}
                style={{ marginTop: 20 }}
            >
                <Input placeholder="Укажите WBTokenV3" />
            </Form.Item>
            <Divider />
            <Text type="secondary">
                Мы создадим рекламную кампанию для текущей интеграции. Вам останется только запустить ее — сделать это
                можно в разделе «Продвижение товаров».
            </Text>
            <Form.Item
                className="action-buttons-block"
                style={{ marginTop: 20 }}
            >
                <Button onClick={() => handleBack()}>Отменить</Button>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isAdding}
                >
                    Сохранить
                </Button>
            </Form.Item>
        </Form>
    );
};
export default WildberriesIntegrationForm;
