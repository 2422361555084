export const ymMarketingId = process.env.YM_MARKETING_ID ? Number(process.env.YM_MARKETING_ID) : undefined;
export const ymBusinessId = process.env.YM_BUSINESS_ID ? Number(process.env.YM_BUSINESS_ID) : undefined;

export const hasYmAccounts = !!ymMarketingId || !!ymBusinessId;

export const ymMarketingAccounts = ymMarketingId ? [ymMarketingId] : [];
export const ymBusinessAccounts = ymBusinessId ? [ymBusinessId] : [];

export const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
};

export const ymBusinessOptions = { ...ymOptions, webvisor: true };

export type ymAccountTypes = 'all' | 'marketing' | 'business';
