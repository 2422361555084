import { useEffect, useState } from 'react';

import { closeModal } from '@Entities/modals/model/modalsSlice';
import { selectModals } from '@Entities/keyValue/selectors';
import { useAppDispatch, useAppSelector } from '@Shared/hooks';
import { Modal, Typography, Row, Button, Col, Grid, Form } from '@Shared/ui';
import { ModalKeysType } from '@Shared/model/modalTypes';
import { Dragger, getUploadFilesAccept, uploadFileTypes } from '@Shared/ui/Dragger';
import { uploadFileRequest } from '@Shared/utils/uploadFileRequest';
import { DraggerProps } from '@Shared/ui/Dragger/types';
import { showMessage, MessageType } from '@Entities/messages/model/messagesSlice';
import { MessageKeysType } from '@Shared/model/messageKeysType';

const BASE_URL = process.env.SERVER_URL;

const { Title, Text, Link } = Typography;
const { useBreakpoint } = Grid;

const ImportCampaignProductsModal = ({ onSuccess }: { onSuccess: (successMessage: string) => void }) => {
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoint();
    const modalState = useAppSelector(selectModals);
    const [form] = Form.useForm();
    const campaignProductsFileId = Form.useWatch('campaignProductsFileId', form);

    const campaignSlug = modalState?.params?.campaignSlug;
    const [isVisible, setIsVisible] = useState(false);
    const isOpen = modalState.modalKey === ModalKeysType.importCampaignProducts && modalState.open;

    const [isImporting, setIsImporting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const [uploadError, setUploadError] = useState<DraggerProps['error']>(null);

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
            form.resetFields();
        }
    }, [isOpen, form]);

    const handleCancel = () => {
        dispatch(closeModal());
        form.setFieldValue('campaignProductsFileId', '');
        setIsVisible(false);
    };

    const handleClickImport = async () => {
        const successMessage = 'Загрузка товаров успешно завершена';
        try {
            setIsImporting(true);

            const response = await fetch(`${BASE_URL}/api/seller/v1/campaign/${campaignSlug}/product/bulk/`, {
                credentials: 'include',
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ file_id: campaignProductsFileId }),
            });

            form.resetFields();
            handleCancel();
            const data = await response.json();
            const { errors, updated } = data;
            if (updated) {
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Success,
                            content: successMessage,
                        },
                    }),
                );
                onSuccess(`Загрузка товаров успешно завершена. Изменено ${updated} строк.`);
            }

            if (errors?.length) {
                dispatch(
                    showMessage({
                        componentProps: {
                            type: MessageType.Error,
                            content: MessageKeysType.importErrorMessage,
                            duration: 5,
                        },
                        messageProps: {
                            errors,
                        },
                    }),
                );
            }
        } catch (error) {
            dispatch(
                showMessage({
                    componentProps: {
                        type: MessageType.Error,
                        content: 'Не удалось импортировать товары',
                    },
                }),
            );
        }
        setIsImporting(false);
    };

    const initialValues = {
        campaignProductsFileId: '',
    };

    const draggerProps: DraggerProps = {
        name: 'campaignProductsFileId',
        maxCount: 1,
        text: 'Перетащите или выберите файл с товарами для рекламной кампании в эту область для загрузки',
        hint: 'Поддержка одиночной загрузки.',
        maxFileSize: 5 * 1024 * 1024,
        accept: getUploadFilesAccept(uploadFileTypes.xlsx),
        customRequest: uploadFileRequest,
        onChange(info) {
            const { status } = info.file;
            if (info.file.status === 'uploading') {
                setIsUploading(true);
            } else {
                setIsUploading(false);
            }
            if (status === 'done') {
                form.setFieldValue('campaignProductsFileId', info.file.response);
            }
        },
        onError: setUploadError,
        beforeUpload: () => {
            form.setFieldValue('campaignProductsFileId', '');
        },
        onRemove: () => form.setFieldValue('campaignProductsFileId', ''),
    };

    const isImportDisabled = Boolean(uploadError) || !campaignProductsFileId || isImporting || isUploading;

    if (!isVisible) {
        return null;
    }

    return (
        <Modal
            title={
                <Title
                    level={4}
                    style={{
                        marginTop: 0,

                        maxWidth: 'calc(100% - 40px)',
                    }}
                >
                    Настроить комиссию и видимость товаров
                </Title>
            }
            open={isOpen}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                form={form}
                name="export-products-form"
                autoComplete="off"
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleClickImport}
            >
                <Text style={{ marginBottom: 20, display: 'inline-block' }}>
                    Вы можете обновить параметры своих товаров в рамках рекламной кампании с помощью загрузки
                    XLSX-файла.
                </Text>

                <Text style={{ marginBottom: 20, display: 'inline-block' }}>
                    Воспользуйтесь&nbsp;
                    <Link
                        href="https://storage.yandexcloud.net/uploads-prod/public/template_for_import_products.xlsx"
                        underline
                        target="_blank"
                    >
                        шаблоном
                    </Link>
                    , чтобы правильно настроить комиссию и видимость товаров, а затем загрузите полученный файл в
                    систему.
                </Text>

                <Form.Item
                    name="campaignProductsFileId"
                    label="Готовый файл с товарами"
                    required
                >
                    <Dragger {...draggerProps} />
                </Form.Item>
                <Row
                    justify="space-between"
                    wrap={false}
                    style={{ flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}
                >
                    <Col>
                        <Button
                            onClick={handleCancel}
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                        >
                            {isImporting ? 'Закрыть' : 'Отменить'}
                        </Button>
                    </Col>
                    <Col style={{ display: 'flex', flexDirection: breakpoints.sm ? 'row' : 'column', gap: 8 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: breakpoints.sm ? 'auto' : '100%' }}
                            loading={isImporting}
                            disabled={isImportDisabled}
                        >
                            {isImporting ? 'Настройка товаров...' : 'Настроить'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default ImportCampaignProductsModal;
