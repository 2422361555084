export const uploadFileRequest = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    fmData.append('file', file);
    try {
        const res = await fetch(`${process.env.SERVER_URL}/api/private/file/upload/`, {
            credentials: 'include',
            method: 'POST',
            body: fmData,
        });
        const data = await res.json();
        onSuccess(data?.fileName);
    } catch (err) {
        onError({ err });
    }
};
